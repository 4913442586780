import React from 'react';

function EmptyTablePage({ screenHeight, emptyPageIcon, pageContent }) {
  return (
    <>
      <div className={`flex flex-col items-center justify-center w-full ${screenHeight}`}>
        <div>{emptyPageIcon}</div>
        <div className="mt-5 text-base text-center text-[#424242]">{pageContent}</div>
      </div>
    </>
  );
}

export default EmptyTablePage;
