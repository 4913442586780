import React from 'react';
import User from 'components/base/UserDetails/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';

function UserDetails(props) {
  const { data, setEditProfilePic } = props;

  return (
    <div className="relative">
      <User data={data} size="md" additionalContent={data?.external_id} avatarProps={{ size: 'xl' }} />
      <FontAwesomeIcon
        data-testid="pencil-icon"
        icon={faPen}
        className={classNames(
          'font-semibold absolute rounded-full bg-blue-700 text-white top-12 left-14 w-3 h-3 p-2 cursor-pointer',
        )}
        onClick={() => setEditProfilePic(prev => !prev)}
      />
    </div>
  );
}

export default UserDetails;
