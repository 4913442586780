export const DEFAULT_ROUTE_PERMISSIONS = [
  // '/settings',
  '/login',
  '/logout',
  '/home',
  '/question-bank/:qbid',
  '/question-bank',
  '/question-bank/request/:qbid',
  '/library',
  '/library/create',
  '/library/edit/:aid',
  '/catalog',
  '/sme/review-question-list',
  '/sme',
  '/sme/request/:skid',
  '/pdf-report',
];
export const ROUTE_PERMISSIONS = {
  // eslint-disable-next-line max-len
  Admin: [
    '/question-bank',
    '/question-bank/:qbid',
    '/question-bank/request/:qbid',
    '/taxonomy',
    '/library',
    '/admin',
    '/sme/review-question-list',
    '/sme/request/:skid',
  ],
  'Content Manager': [
    '/question-bank',
    '/question-bank/request/:qbid',
    '/question-bank/:qbid',
    '/taxonomy',
    '/library',
    '/sme/request/:skid',
  ],
  'Subject Matter Expert': ['/sme', '/sme/request/:skid', '/sme/review-question-list'],
};
