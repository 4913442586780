import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUser } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { toTitleCase } from 'utils/helpers';
import messages from './messages';

function RoleOrganizations(props) {
  const data = props;
  const role = toTitleCase(
    localStorage.getItem('tv.ae') ? JSON.parse(localStorage.getItem('tv.ae')).role : ['Content Manager'],
  );

  return (
    <div className="p-4 bg-white border rounded-lg">
      <div className="mb-2 text-sm font-semibold text-gray-900">
        <FormattedMessage {...messages.rolesAndOrganization} />
      </div>
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faUser} className="w-4 p-2 text-blue-700 rounded-full bg-blue-50" />
        <div className="ml-2 text-sm text-gray-900">{role}</div>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faBriefcase} className="w-4 p-2 text-blue-700 rounded-full bg-blue-50" />
        <div className="ml-2 text-sm text-gray-900">{data?.userDetails?.tenant?.name || '-'}</div>
      </div>
    </div>
  );
}

export default RoleOrganizations;
