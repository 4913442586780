/**
 *
 * Calendar
 *
 */

import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { DateTime } from 'luxon';
import { map, round } from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export function Calendar(props) {
  const { value, onChange, min, max, currentValue, prependLabel } = props;
  const isMounted = useRef(false);

  const [selected, setSelected] = useState(value ? DateTime.fromISO(value).toISODate() : null);
  const [current, setCurrent] = useState(
    value || currentValue ? DateTime.fromISO(value || currentValue) : DateTime.now(),
  );
  useEffect(() => {
    if (isMounted.current) {
      if (onChange) onChange(selected);
    } else {
      isMounted.current = true;
    }
  }, [selected]);

  const start = current.startOf('Month').minus({ days: current.startOf('Month').weekday - 1 });

  const diff = round(
    current
      .endOf('Month')
      .plus({ days: 7 - current.endOf('Month').weekday })
      .diff(start, 'days')
      .toObject().days,
  );
  const dMax = min ? DateTime.fromISO(min) : start.plus({ days: diff + 1 }).toFormat('yyyy-MM-dd');
  const dMin = max ? DateTime.fromISO(max) : start.minus({ days: 1 }).toFormat('yyyy-MM-dd');

  const days = map(Array.from(Array(diff).keys()), i => ({
    date: start.plus({ days: i }).toFormat('yyyy-MM-dd'),
    isCurrentMonth: current.month === start.plus({ days: i }).month,
    isToday: start.plus({ days: i }).toISODate() === DateTime.now().toISODate(),
    isDisabled: start.plus({ days: i }) > dMin || start.plus({ days: i }) < dMax,
    isSelected: selected === start.plus({ days: i }).toISODate(),
  }));
  return (
    <div className="max:w-full lg:w-72 md:w-64 sm:w-48 mt-2">
      <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1  xl:col-start-9">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            onClick={() => setCurrent(current.minus({ month: 1 }))}
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">
              <FormattedMessage {...messages.previous_month} />
            </span>
            <ChevronLeftIcon data-testid="prev" className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="flex-auto font-semibold">
            {prependLabel} {current.toFormat('MMMM, yyyy')}
          </div>
          <button
            type="button"
            onClick={() => setCurrent(current.plus({ month: 1 }))}
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">
              <FormattedMessage {...messages.next_month} />
            </span>
            <ChevronRightIcon data-testid="next" className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-2 grid grid-cols-7 text-xs leading-2 md:leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div
          className={classNames(
            'isolate mt-2 grid grid-cols-7 gap-px',
            'rounded-lg bg-gray-200 text-xs sm:text-sm shadow ring-1 ring-gray-200',
          )}
        >
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              disabled={day.isDisabled || !day.isCurrentMonth}
              onClick={() => setSelected(day.date)}
              className={classNames(
                'py-1.5  focus:z-10',
                day.isDisabled ? 'bg-gray-50 text-gray-400 cursor-not-allowed' : 'hover:bg-gray-100',
                day.isCurrentMonth ? 'bg-white hover:bg-gray-100 cursor-pointer' : 'bg-gray-50 cursor-not-allowed',
                (day.isSelected || day.isToday) && 'font-semibold',
                day.isSelected && 'text-white',
                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                day.isToday && !day.isSelected && 'text-indigo-600',
                dayIdx === 0 && 'rounded-tl-sm',
                dayIdx === 6 && 'rounded-tr-sm',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg',
              )}
            >
              {day.isCurrentMonth && (
                <time
                  dateTime={day.date}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    day.isSelected && day.isToday && 'bg-indigo-600',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

Calendar.propTypes = {
  value: PropTypes.string,
  currentValue: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onChange: PropTypes.func,
  prependLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default memo(Calendar);
