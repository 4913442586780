import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

const defaultMinutes = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];
const defaultSeconds = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '60'];

export function TimeInput(props) {
  const { onChange, error, time, customMinutes, customSeconds } = props;
  const value = time.split(':');
  const [selectedTime, setSelectedTime] = useState({ hrs: value[0], min: value[1], sec: value[2] });
  useEffect(() => {
    const timestr = time.split(':');
    setSelectedTime({ hrs: timestr[0], min: timestr[1], sec: timestr[2] });
  }, [props.time]);

  const minutes = customMinutes || defaultMinutes;
  const seconds = customSeconds || defaultSeconds;

  const onchangeMinute = val => {
    const obj = { ...selectedTime };
    if (val === '10') {
      obj.sec = '00';
    }
    obj.min = val;
    setSelectedTime(obj);
    if (onChange) onChange(obj);
  };
  const onchangeSecond = val => {
    const obj = { ...selectedTime };
    if (val === '60') {
      const minuteIndex = minutes.findIndex(min => min === selectedTime.min) + 1;
      obj.min = minutes[minuteIndex];
      obj.sec = '00';
    } else {
      obj.sec = val;
    }
    setSelectedTime(obj);
    if (onChange) onChange(obj);
  };

  return (
    <>
      <div
        className={classNames(
          'flex items-center justify-center  rounded-md',
          'w-44',

          error
            ? 'focus:ring-red-500 focus:border-red-500 border-2 border-red-300 text-red-900 placeholder-red-300'
            : 'border-2 border-gray-200',
        )}
      >
        <div className="w-1/2 max-w-xs">
          <Listbox as="div" className={classNames('space-y-1')} value={selectedTime.min} onChange={onchangeMinute}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700"></Listbox.Label>
                <div className="relative">
                  <span className="inline-block w-full rounded-md" id="timer">
                    <Listbox.Button
                      className="cursor-default relative w-full rounded-md border-none bg-white
                     pl-1 pr-0 py-2 text-center focus:outline-none focus:border-none transition ease-in-out 
                     duration-150 sm:text-sm sm:leading-5"
                    >
                      <span className="block truncate">{selectedTime.min}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none"></span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    show={open}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute mt-1 w-full rounded-md bg-white md:text-xs sm:text-xs"
                  >
                    <Listbox.Options
                      static
                      className="max-h-60 rounded-md py-1 text-base leading-6 overflow-auto 
                      focus:outline-none sm:text-sm sm:leading-5"
                    >
                      {minutes &&
                        minutes.map(minute => (
                          <Listbox.Option key={minute} value={minute}>
                            {({ selected, active }) => (
                              <div
                                className={`${
                                  active ? 'bg-zinc-100 text-gray-900' : 'text-gray-900'
                                } cursor-default select-none relative lg:py-2 lg:pl-8 lg:pr-4 md:py-2 
                              md:pl-8 md:pr-4 pl-1`}
                              >
                                <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                  {minute}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active ? 'text-sky-600' : 'text-sky-600'
                                    } absolute inset-y-0 left-0 flex items-center lg:pl-1.5 pl-0.5`}
                                  >
                                    <svg
                                      className="lg:h-4 lg:w-4 md:h-4 md:w-4  sm:w-0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 
                                      011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <p className="">:</p>
        <div className="w-1/2 max-w-xs">
          <Listbox
            as="div"
            className="space-y-1"
            value={selectedTime.sec}
            onChange={onchangeSecond}
            disabled={selectedTime.min === '10'}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700"></Listbox.Label>
                <div className="relative">
                  <span className="inline-block w-full rounded-md">
                    <Listbox.Button
                      className={classNames(
                        'cursor-default relative w-full rounded-md border-none bg-white',
                        'pl-0 pr-0 py-2 text-center focus:outline-none focus:shadow-outline-blue',
                        'focus:border-none transition ease-in-out duration-150 sm:text-sm sm:leading-5',
                        selectedTime.min === '10' ? 'text-gray-300' : '',
                      )}
                    >
                      <span className="block truncate">{selectedTime.sec}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"></span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    show={open}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute mt-1 w-full rounded-md bg-white shadow-lg md:text-xs sm:text-xs"
                  >
                    <Listbox.Options
                      static
                      className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto 
                      focus:outline-none sm:text-sm sm:leading-5"
                    >
                      {seconds &&
                        seconds.map(second => (
                          <Listbox.Option key={second} value={second}>
                            {({ selected, active }) => (
                              <div
                                className={`${
                                  active ? 'bg-zinc-100 text-gray-900' : 'text-gray-900'
                                } cursor-default select-none relative lg:py-2 lg:pl-8 lg:pr-4 md:py-2 
                              md:pl-8 md:pr-4 pl-1`}
                              >
                                <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                  {second}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active ? 'text-white' : 'text-sky-600'
                                    } absolute inset-y-0 left-0 flex items-center pl-1.5 lg:pl-1.5 sm:pl-0.5`}
                                  >
                                    <svg
                                      className="lg:h-4 lg:w-4 md:h-4 md:w-4  sm:w-0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 
                                      1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <p className="text-xs text-red-600">{error}</p>
    </>
  );
}
TimeInput.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  time: PropTypes.string,
};

TimeInput.defaultProps = {
  time: '00:00:00',
};

export default memo(TimeInput);
