import { memoize, map, filter, isArray, isNull, reject, get } from 'lodash';
import Auth, {
  ROLE_ADMIN,
  ROLE_MASTER_CONTENT_MANAGER,
  ROLE_SUBJECT_MATTER_EXPERT,
  MASTER_CONTENT_MANAGER,
  ROLE_CONTENT_MANAGER,
} from 'utils/auth';
import { faFolder, faNewspaper, faSwatchbook, faNetworkWired, faClipboardList } from '@fortawesome/pro-light-svg-icons';
import message from './messages';

export const filterItems = ({ items, roles }) =>
  reject(
    map(items, item => {
      let returnItem = item;
      const hasItem =
        get(item, 'roles', []).filter(e => roles && roles.includes(e)).length > 0 &&
        !(get(item, 'excludes', []).filter(e => roles && roles.includes(e)).length > 0);
      if (isArray(item.subItems)) {
        returnItem = filter(item.subItems, subItem => subItem.roles.filter(e => roles && roles.includes(e)).length > 0);
      }
      return hasItem ? returnItem : null;
    }),
    isNull,
  );

export const getItems = () => {
  const roles = Auth.roles || [];
  const items = [
    {
      path: '/question-bank',
      label: message.banks,
      name: 'Question Bank',
      icon: faFolder,
      roles: [
        ROLE_ADMIN,
        ROLE_MASTER_CONTENT_MANAGER,
        MASTER_CONTENT_MANAGER,
        ROLE_SUBJECT_MATTER_EXPERT,
        ROLE_CONTENT_MANAGER,
      ],
    },
    {
      path: '/library',
      label: message.library,
      name: 'Library',
      icon: faNewspaper,
      roles: [
        ROLE_ADMIN,
        ROLE_MASTER_CONTENT_MANAGER,
        MASTER_CONTENT_MANAGER,
        ROLE_SUBJECT_MATTER_EXPERT,
        ROLE_CONTENT_MANAGER,
      ],
    },
    {
      path: '/catalog',
      label: message.catalog,
      name: 'Catalog',
      icon: faSwatchbook,
      roles: [
        ROLE_ADMIN,
        ROLE_MASTER_CONTENT_MANAGER,
        MASTER_CONTENT_MANAGER,
        ROLE_SUBJECT_MATTER_EXPERT,
        ROLE_CONTENT_MANAGER,
      ],
    },
    {
      path: '/skills',
      label: message.skills,
      name: 'Skills',
      icon: faNetworkWired,
      roles: [
        ROLE_ADMIN,
        ROLE_MASTER_CONTENT_MANAGER,
        MASTER_CONTENT_MANAGER,
        ROLE_SUBJECT_MATTER_EXPERT,
        ROLE_CONTENT_MANAGER,
      ],
    },
    {
      path: '/reports',
      label: message.reports,
      name: 'Reports',
      icon: faClipboardList,
      roles: [
        ROLE_ADMIN,
        ROLE_MASTER_CONTENT_MANAGER,
        MASTER_CONTENT_MANAGER,
        ROLE_SUBJECT_MATTER_EXPERT,
        ROLE_CONTENT_MANAGER,
      ],
    },
    // {
    //   path: '/more-apps',
    //   label: message.apps,
    //   name: 'More Apps',
    //   icon: faShapes,
    //   roles: [
    //     ROLE_ADMIN,
    //     ROLE_MASTER_CONTENT_MANAGER,
    //     MASTER_CONTENT_MANAGER,
    //     ROLE_SUBJECT_MATTER_EXPERT,
    //     ROLE_CONTENT_MANAGER,
    //   ],
    //   isChevronRight: true,
    // },
  ];

  return filterItems({ items, roles });
};

export default memoize(getItems);
