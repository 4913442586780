import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

export const Badges = props => {
  const { text, backgroundColor, variant, size } = props;
  const colorMap = {
    baseYellow: 'bg-yellow-100 text-amber-800',
    baseGreen: 'bg-green-100 text-green-800',
    baseRed: 'bg-red-100 text-red-800',
    baseBlue: 'bg-sky-50 text-sky-800',
    baseGray: 'bg-[#EFF0F6] text-[#4F5E74] ',
    blueGray: 'bg-[#f1f5f9] text-blue-900',
    baseViloet: 'bg-[#EDE9FE] text-[#5B21B6]',
    baseAmber: 'bg-[#FFEDD5] text-[#C2410C]',
    gray: 'bg-[#F3F4F6] text-[#1F2937]',
    baseOrange: 'bg-orange-100 text-orange-700',
  };

  const styleMap = {
    rounded: 'rounded-full',
    rectangle: 'rounded ',
    info: 'rounded-full',
  };

  const sizeMap = {
    xs: 'px-2 py-0.5 text-xs font-medium justify-center ',
    sm: 'px-8 py-1 text-xs font-medium justify-center max-w-[6rem]  ',
    md: 'px-2 py-1 text-xs font-medium justify-center max-w-[7rem]  ',
    xxs: 'px-4 py-1 text-xs font-medium justify-center text-center w-fit',
    base: 'px-2 py-1 text-xs font-medium justify-start max-w-[7rem]',
    lg: 'px-2 py-1 text-xs font-medium justify-center max-w-[7rem]  ',
    xl: 'px-2 py-1 text-xs font-medium justify-center max-w-[11rem]',
  };

  const widthMap = {
    xs: 'w-14',
    sm: 'w-16',
    md: 'w-18',
    lg: 'w-28',
    xl: 'w-44	',
  };

  return (
    <>
      {variant === 'info' ? (
        <div
          key={`badge-${variant}`}
          className={classNames(
            `relative mr-2`,
            get(colorMap, backgroundColor),
            get(styleMap, variant),
            get(sizeMap, size),
          )}
        >
          <span className="relative whitespace-nowrap">{text}</span>
        </div>
      ) : (
        <div
          key={`badge-${variant}`}
          className={classNames(
            ` ${variant === 'rectangle' ? get(widthMap, size) : ''}`,
            `md`,
            get(colorMap, backgroundColor),
            get(styleMap, variant),
            get(sizeMap, size),
          )}
        >
          <span className="">{text}</span>
        </div>
      )}
    </>
  );
};

Badges.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.oneOf([
    'baseYellow',
    'baseOrange',
    'baseGreen',
    'baseRed',
    'baseBlue',
    'blueGray',
    'baseViloet',
    'gray',
    'baseAmber',
  ]),
  variant: PropTypes.oneOf(['rounded', 'rectangle', 'info']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'xxs', 'base']),
};

export default memo(Badges);
