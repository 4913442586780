import { createSelector } from 'reselect';
import { filter, find } from 'lodash';
import { ROLE_EVALUATOR } from 'utils/roles';
import { initialState } from './reducer';

// const selectGlobal = (state) => state.global || initialState;
const selectRouter = state => state.router;

const selectApp = state => state.App || initialState;

const selectAppState = createSelector(selectApp, state => state);

const selectUser = createSelector(selectApp, state => state.userDetails);

const selectTasks = state => state || [];

const selectSaved = state => state.saved || initialState;

const makeSelectLocation = () => createSelector(selectRouter, routerState => routerState.location);

const makeSelectError = () => createSelector(selectApp, state => state.error);

const makeSelectTasks = () => createSelector(selectTasks, state => state);

const makeSelectEnv = () => createSelector(selectApp, state => state.env);

const makeSelectApp = () => createSelector(selectApp, state => state);

const makeSelectSaved = () => createSelector(selectSaved, savedState => savedState);

const selectMetaData = createSelector(selectApp, state => {
  if (state?.user?.old_id) {
    return state?.meta;
  }
  return {
    ...state?.meta,
    providers: state?.meta?.providers?.filter(provider => provider?.meeting_service_provider_name !== 'RECRUIT'),
  };
});
const selectAuthEvaluators = createSelector(selectMetaData, ({ authUsers = [] }) =>
  filter(authUsers, authUser => find(authUser.roles, ['role', ROLE_EVALUATOR]) && authUser?.email),
);
const selectTimeZone = createSelector(selectApp, state => state.timezones);

export {
  makeSelectLocation,
  makeSelectTasks,
  makeSelectError,
  makeSelectEnv,
  makeSelectSaved,
  makeSelectApp,
  selectAppState,
  selectUser,
  selectMetaData,
  selectAuthEvaluators,
  selectTimeZone,
};
