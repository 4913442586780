/* eslint-disable no-param-reassign */
import React from 'react';
import { memoize, map, filter, isArray, isNull, reject, get } from 'lodash';
import catalog_list from 'images/catalog_list.svg';
import { SparklesIcon, ClipboardListIcon, MenuIcon, ViewGridIcon, NewspaperIcon } from '@heroicons/react/outline';
import { ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER, ROLE_SUBJECT_MATTER_EXPERT } from 'utils/auth';
import message from './messages';

export const filterItems = ({ items, roles }) =>
  reject(
    map(items, item => {
      const hasItem =
        get(item, 'roles', []).filter(e => roles && roles?.includes(e)).length > 0 &&
        !(get(item, 'excludes', []).filter(e => roles && roles?.includes(e)).length > 0);
      if (isArray(item.subItems)) {
        item.subItems = filter(
          item.subItems,
          subItem => subItem.roles.filter(e => roles && roles.includes(e)).length > 0,
        );
      }
      return hasItem ? item : null;
    }),
    isNull,
  );

export const getItems = roles => {
  const items = [
    {
      label: message.banks,
      index: '/question-bank',
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER],
      icon: <ViewGridIcon className="w-4 h-4" />,
    },
    {
      index: '/library',
      label: message.library,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER],
      icon: <MenuIcon className="w-4 h-4" />,
    },
    {
      index: '/catalog',
      label: message.catalog,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER],
      icon: <img src={catalog_list} alt="starGroup" />,
    },
    {
      index: '/skills',
      label: message.skills,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER],
      icon: <SparklesIcon className="w-4 h-4" />,
    },
    {
      index: '/reports',
      label: message.reports,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER],
      icon: <ClipboardListIcon className="w-4 h-4" />,
    },
    {
      index: '/sme',
      label: message.sme,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER, ROLE_SUBJECT_MATTER_EXPERT],
    },
    {
      index: '/sme/review-question-list',
      label: message.smeReview,
      roles: [ROLE_ADMIN, ROLE_MASTER_CONTENT_MANAGER, ROLE_SUBJECT_MATTER_EXPERT],
      icon: <NewspaperIcon className="w-4 h-4" />,
    },
  ];
  return filterItems({ items, roles });
};

export const getRole = role => {
  let result = '';
  switch (role) {
    case 'MASTER_CONTENT_MANAGER':
      result = ROLE_MASTER_CONTENT_MANAGER;
      break;
    case 'SUBJECT_MATTER_EXPERT':
      result = ROLE_SUBJECT_MATTER_EXPERT;
      break;
    default:
      result = ROLE_ADMIN;
  }
  return [result];
};

export default memoize(getItems);
