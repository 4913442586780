import classNames from 'classnames';
import React, { useState } from 'react';
import { handleEnterKeyPress } from 'utils/helpers';
import Profile from './Contents/Profile View/index';
import ChangeLanguage from './Contents/ChangeLanguage';
import TimeZone from './Contents/Profile View/TimeZone/TimeZone';
import ChangePassword from './Contents/ChangePassword';

import Tooltip from './Tooltip';

const renderComponentByName = (componentName, userDetails) => {
  switch (componentName) {
    case 'profile':
      return <Profile userDetails={userDetails} />;
    case 'language':
      return <ChangeLanguage />;
    case 'password':
      return <ChangePassword />;
    case 'timezone':
      return <TimeZone />;
    default:
      return (
        <>
          <div className="flex items-center justify-center h-full">
            Get Ready for an Exciting New Feature - Coming Soon!
          </div>
        </>
      );
  }
};
const Content = ({ options, userDetails }) => {
  const [component, setComponent] = useState('profile');

  return (
    <div className="w-[880px] flex h-[619px] border-t border-gray-200">
      <div className="w-[278px] pr-5 pt-4">
        {options?.map(option => (
          <div
            role="button"
            tabIndex={-1}
            className={classNames(
              'flex gap-x-2 px-[18px] py-[6px] items-center mb-2 w-[235px] hover:bg-gray-100',
              option?.value === component ? 'bg-blue-50' : '',
            )}
            onClick={() => setComponent(option?.value)}
            onKeyDown={e => handleEnterKeyPress(e, () => setComponent(option?.value))}
            key={option?.value}
          >
            <span className={classNames(option?.value === component ? 'text-blue-700 ' : 'text-gray-900')}>
              {option?.icon}
            </span>
            <Tooltip text={option?.label}>
              <span
                className={classNames(
                  'text-sm truncate max-w-[210px]',
                  option?.value === component ? 'text-blue-700 font-medium' : 'text-gray-900 font-normal',
                )}
              >
                {option?.label}
              </span>
            </Tooltip>
          </div>
        ))}
      </div>
      {/* Rendering on the basis of state change */}

      <div className="w-full rounded bg-gray-50">{renderComponentByName(component, userDetails)}</div>
    </div>
  );
};

export default Content;
