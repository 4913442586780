import { refetchUserData } from '../App/actions';
import * as types from './constants';
import { UPDATE_PASSWORD_QUERY, UPDATE_USER_QUERY } from './queries';

export const updateUser = ({ payload, callback }) => ({
  type: types.UPDATE_USER,
  payload,
  callback,
  queryString: UPDATE_USER_QUERY,
  key: 'auth_update_user',
  refetchActions: [refetchUserData],
});

export const fetchFileUrl = ({ queryVariables, callback }) => ({
  type: types.FETCH_FILE_URL,
  queryVariables,
  callback,
});

export const changePassword = ({ payload, callback }) => ({
  type: types.UPDATE_PASSWORD,
  payload,
  callback,
  queryString: UPDATE_PASSWORD_QUERY,
  key: 'auth_update_user_password',
});
