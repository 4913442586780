import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import { NavigationIcon } from '../NavigationIcon';

const NavModules = props => {
  const { expanded, iconArray } = props;
  const location = useLocation();
  const history = useHistory();

  const filteredRoutes = iconArray;
  const routes = filteredRoutes?.map(route => ({
    ...route,
    label: <FormattedMessage {...route.label} />,
  }));

  const handleClick = item => {
    if (item?.path) {
      history.push(item?.path);
    }
  };

  return (
    <div className="flex flex-col pt-8 gap-y-4">
      {routes?.map(module => (
        <NavigationIcon
          item={module}
          icon={module.icon}
          key={module.name}
          label={module.label}
          expanded={expanded}
          selected={location?.pathname?.includes(module?.path)}
          onClick={handleClick}
          name={module.name}
          isChevronRight={module.isChevronRight}
        />
      ))}
    </div>
  );
};

// NavModules.propTypes = {};

export default memo(NavModules);
