/*
 * Nav Messages
 *
 * This contains all the text for the Nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Calendar';

export default defineMessages({
  previous_month: {
    id: `${scope}.previous_month`,
    defaultMessage: 'Previous Month',
  },
  next_month: {
    id: `${scope}.next_month`,
    defaultMessage: 'Next Month',
  },
});
