/**
 *
 * Error
 *
 */

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { BanIcon, HomeIcon, ClipboardCopyIcon } from '@heroicons/react/solid';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import Img from 'images/500.svg';
import { Button, Notification } from 'components/base';

import messages from './messages';

export function Error(props) {
  const { error } = props;
  const [clipError, setClipError] = useState(false);
  const history = useHistory();
  const trace = !['production', 'test'].includes(process.env.NODE_ENV);
  useEffect(() => {
    if (error) {
      if (trace) {
        // eslint-disable-next-line no-console
        console.error(error.stack);
      } else {
        Sentry.captureException(error);
      }
    }
  }, []);
  const onClickCopy = () => {
    try {
      if (error) navigator.clipboard.writeText(error.stack);
    } catch (e) {
      Sentry.captureException(e);
      setClipError(true);
    }
  };
  return (
    <div className="h-full bg-slate-50 relative">
      {clipError && (
        <Notification header="Clipboard" type="danger">
          <FormattedMessage {...messages.clipError} />
        </Notification>
      )}
      <div className="h-full flex justify-center items-center ">
        <div className="flex shadow-lg items-center p-6 rounded-md bg-white ">
          <div className="flex flex-grow-0 flex-col space-y-8 flex-shrink-0 w-full md:w-1/2 md:max-w-lg">
            <h2 className="flex- md:text-2xl text-2xl font-semibold leading-8 align-middle items-center">
              <BanIcon className="w-7 h-7 text-red-600 inline md:hidden" /> <FormattedMessage {...messages.header} />
            </h2>
            <p className="text-lg break-word  overflow-scroll  ">
              {error && trace ? (
                <pre className="break-all text-sm bg-gray-100 max-h-48 p-4">{error.stack}</pre>
              ) : (
                <FormattedMessage {...messages.body} />
              )}
            </p>
            <div className="max-w-md space-x-4">
              {error && (
                <Button type="secondary" size="md" LeadingIcon={ClipboardCopyIcon} onClick={onClickCopy}>
                  <FormattedMessage {...messages.copy} />
                </Button>
              )}

              <Button type="primary" size="md" LeadingIcon={HomeIcon} onClick={() => history.push('/')}>
                <FormattedMessage {...messages.home} />
              </Button>
            </div>
          </div>
          <div className="md:max-w-lg md:w-1/2 hidden md:block p-8 flex-grow-0 flex flex-col space-y-2">
            <img src={Img} alt="Forbidden" className="w-full" />
            <p className="text-xs float-right font-extralight text-gray-300 italic">
              <FormattedMessage {...messages.dave} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.object,
};

export default memo(Error);
