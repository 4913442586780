/* eslint no-param-reassign: "off" */
import axios from 'axios';
import { get, toNumber, isNil } from 'lodash';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga';

import { parseJwt } from './helpers';
import Apollo from './apollo';

export const ROLE_ADMIN = 'Admin';
export const ROLE_MASTER_CONTENT_MANAGER = 'Content Manager';
export const ROLE_CONTENT_MANAGER = 'CONTENT_MANAGER';
export const MASTER_CONTENT_MANAGER = 'MASTER_CONTENT_MANAGER';
export const ROLE_SUBJECT_MATTER_EXPERT = 'SUBJECT_MATTER_EXPERT';
class Auth {
  constructor() {
    this.expires = null;
    if (!Auth.instance) {
      Auth.instance = this;
    }
  }

  get isLoggedIn() {
    return !!this.graphToken;
  }

  get hasuraClaims() {
    return get(this.user, 'https://hasura.io/jwt/claims', {});
  }

  get stored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.ae') || '{}');
    } catch (e) {
      Sentry.captureException(e);
    }
    return st;
  }

  get graphToken() {
    return get(this.stored, 'access_token', null);
  }

  get user() {
    return get(this.stored, 'user', null);
  }

  get id() {
    return toNumber(get(this.stored, 'user.uid', null));
  }

  get roles() {
    return get(this.stored, 'roleList', null);
  }

  get role() {
    return get(this.stored, 'role', null);
  }

  async client() {
    return Apollo.createClient({ graphToken: this.graphToken, role: this.role });
  }

  removeClient() {
    Apollo.removeClient();
  }

  async createClient(errorCallback) {
    return Apollo.createClient({
      graphToken: this.graphToken,
      errorCallback,
      role: get(this.roles, '0'),
    });
  }

  async processToken(access_token) {
    await this.logout();
    const user = parseJwt(access_token);
    const rest_token = user['rest-token'];
    const default_role = user['https://hasura.io/jwt/claims']['X-Hasura-Default-Role'];
    const userId = user['https://hasura.io/jwt/claims']['X-Hasura-User-Id'] || '';
    let role = default_role;
    const roleList = user['https://hasura.io/jwt/claims']['X-Hasura-Allowed-Roles'];
    if (roleList?.includes('SUBJECT_MATTER_EXPERT')) {
      role = 'SUBJECT_MATTER_EXPERT';
    } else if (roleList?.includes('MASTER_CONTENT_MANAGER')) {
      role = 'MASTER_CONTENT_MANAGER';
    } else if (roleList?.includes('CONTENT_MANAGER')) {
      role = 'CONTENT_MANAGER';
    }
    localStorage.setItem('tv.ae', JSON.stringify({ access_token, user, rest_token, role, roleList, userId }));
    this.expires = user.exp;
    Sentry.setUser({ id: user.user_id });
    ReactGA.set({ userId: user.user_id });
    return user;
  }

  async logout() {
    localStorage.removeItem('tv.ae');
    await Apollo.clearCache();
    await Apollo.removeClient();
  }

  async authenticate({ username, password }) {
    if (isNil(username) || isNil(password)) return Promise.reject(new Error('Invalid username/password'));
    const { data } = await axios.post('/api/auth', { username, password });
    if (!data) throw new Error('Invalid username/password');
    const access_token = get(data, 'access_token');
    await this.processToken(access_token);
    return data;
  }
}

export function formatRoles(role) {
  switch (role) {
    case ROLE_ADMIN:
      return 'Admin';
    case ROLE_MASTER_CONTENT_MANAGER:
      return 'Content Manager';
    case ROLE_SUBJECT_MATTER_EXPERT:
      return 'Subject Matter Expert';
    default:
      return role;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const constructFullName = (firstName, lastName, middleName, isOnlyFirstName) => {
  if (firstName || lastName) {
    if (isOnlyFirstName) {
      return capitalizeFirstLetter(firstName);
    }
    return `${capitalizeFirstLetter(firstName)} ${
      middleName ? `${capitalizeFirstLetter(middleName)} ` : ''
    }${capitalizeFirstLetter(lastName)}`;
  }
  return '';
};

export function getDisplayName(data, isOnlyFirstName = false, isOnlyName = false) {
  let fullName = '';
  const name = (data?.name || '').trim();
  if (name) {
    fullName = name;
  } else {
    const firstName = (data?.first_name || '').trim();
    const middleName = (data?.middle_name || '').trim();
    const lastName = (data?.last_name || '').trim();
    fullName = constructFullName(firstName, lastName, middleName, isOnlyFirstName);
  }
  const returnValue = isOnlyName ? fullName : fullName || data?.email;
  return returnValue?.length > 1 ? returnValue : 'TV';
}

export const instance = new Auth();
const FORCE_REFRESH = true;
export const apolloClientWrapper = async (queryText, variablesObject, searchExpString) => {
  const apolloClient = await instance.client();
  try {
    const data = await apolloClient.query({
      query: queryText,
      fetchPolicy: FORCE_REFRESH ? 'network-only' : 'cache-first',
      variables: variablesObject,
      searchExpression: searchExpString,
    });
    return data;
  } catch (e) {
    Sentry.captureException(e);
    return { error: new Error(e) };
  }
};

export default instance;
