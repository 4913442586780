/*
 * SidePanel Messages
 *
 * This contains all the text for the SidePanel component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.SidePanel';

export default defineMessages({
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  close_panel: {
    id: `${scope}.close_panel`,
    defaultMessage: 'Close Panel',
  },
});
