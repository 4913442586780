/**
 *
 * Export
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { delay } from 'lodash';
import download from './download';
import messages from './messages';

function DownloadTemplate(props) {
  const { rows, columns, name, onClick } = props;
  const action = () => {
    onClick().then(
      res =>
        new Promise(resolve => {
          delay(() => download({ rows: res || rows, columns, name }).then(() => resolve()), 200);
        }),
    );
  };
  return (
    <>
      <button type="button" className="font-base cursor-pointer style-none" onClick={action}>
        <FormattedMessage {...messages.download} />
      </button>
    </>
  );
}

DownloadTemplate.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.array,
  rows: PropTypes.array,
  onClick: PropTypes.func,
  showText: PropTypes.bool,
};

DownloadTemplate.defaultProps = {
  name: 'export',
  rows: [],
  showText: true,
  columns: [],
  onClick: () => Promise.resolve(null),
};
export default memo(DownloadTemplate);
