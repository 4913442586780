import { gql } from '@apollo/client';

export const INSERT_MCA_QUESTION = gql`
  mutation InsertCodingQuestion(
    $content: jsonb!
    $level: qbm_question_level_enum
    $question_bank_id: Int!
    $type: qbm_question_type_enum
    $question_choices: qbm_question_choice_arr_rel_insert_input
    $question_skills: qbm_question_skill_arr_rel_insert_input
    $status: String!
    $is_shuffle: Boolean
    $blooms_taxonomy: qbm_question_blooms_taxonomy_arr_rel_insert_input
    $external_id: String
  ) {
    insert_qbm_question(
      objects: {
        external_id: $external_id
        content: $content
        level: $level
        type: $type
        question_bank_id: $question_bank_id
        question_choices: $question_choices
        question_skills: $question_skills
        status: $status
        question_blooms_taxonomies: $blooms_taxonomy
        is_shuffle: $is_shuffle
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export default INSERT_MCA_QUESTION;
