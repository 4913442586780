import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import UserContext from 'contexts/UserContext';
import { Forbidden, Error } from 'components/pages';

import canLogin from './canLogin';

function AuthenticatedRoute({ error, ...props }) {
  const { path } = props;
  const history = useHistory();
  const { client, user } = useContext(UserContext);

  if (!user || user.isAnonymous === true) {
    history.push('/login');
    return <></>;
  }
  return (
    <>
      {user && !user.isAnonymous && (
        <ApolloProvider client={client}>
          <div className="w-full z-0">
            <div className="z-10 h-screen py-5 pt-0">
              {error ? (
                <Error error={error} />
              ) : (
                <>{canLogin({ roles: [], path }) ? <Route {...props} /> : <Forbidden />}</>
              )}
            </div>
          </div>
        </ApolloProvider>
      )}
    </>
  );
}

AuthenticatedRoute.propTypes = {
  path: PropTypes.string,
  location: PropTypes.object,
};

export default AuthenticatedRoute;
