export function badgesmap(value) {
  let color;
  if (value === 'Published') {
    color = 'baseGreen';
  } else if (value === 'Review') {
    color = 'baseRed';
  } else if (value === 'Withdrawn') {
    color = '';
  } else {
    color = 'baseYellow';
  }
  return color;
}
export default badgesmap;
