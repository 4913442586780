/**
 *
 * Notification
 *
 */

import React, { memo, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Transition } from '@headlessui/react';
import { BadgeCheckIcon, BanIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { toast } from 'react-toastify';

export function Notification(props) {
  const { header, children, timeout, type, primary, secondary, variant } = props;
  const [show, setShow] = useState(true);
  useEffect(() => {
    let timer = null;
    if (timeout) {
      timer = setTimeout(() => setShow(false), timeout);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  const positonMap = {
    topright: 'items-end sm:items-start',
    bottomleft: 'sm:items-end',
  };
  const flexMap = {
    topright: 'items-center sm:items-end',
    bottomleft: 'justify-start items-start sm:items-start -bottom-2 left-10',
  };
  const dataMap = {
    success: { Icon: BadgeCheckIcon, color: 'text-emerald-400' },
    danger: { Icon: BanIcon, color: 'text-red-400' },
    warning: { Icon: ExclamationIcon, color: 'text-amber-400' },
    info: { Icon: InformationCircleIcon, color: 'text-indigo-400' },
    default: { Icon: InformationCircleIcon, color: 'text-gray-500' },
  };
  const Icon = get(dataMap, `${type}.Icon`);
  const iconHeight = !header || !children ? 'h-6 w-6' : 'h-12 w-12';
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className={`z-50 inset-0 flex  px-4 py-6 pointer-events-none sm:p-6  ${get(positonMap, variant)} absolute`}
      >
        <div className={`w-full  flex flex-col space-y-4 ${get(flexMap, variant)} `}>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(`w-full max-w-sm p-1 overflow-hidden bg-white rounded-lg 
             shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5`)}
            >
              <div className="flex items-start min-h-full p-1">
                <div className="flex-shrink-0 p-1 ">
                  <Icon className={`${iconHeight} ${get(dataMap, `${type}.color`)}`} aria-hidden="true" />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5 space-y-1 p-1 ">
                  {header && <p className="text-sm font-medium text-gray-900">{header}</p>}
                  {children && <p className="text-sm text-gray-500">{children}</p>}
                </div>
                {!primary && !secondary ? (
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className={classNames(
                        `inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500
                         focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
                      )}
                      onClick={() => setShow(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col border-l divide-y divide-gray-200 border-grey-50 h-14">
                    {primary && (
                      <div className="flex items-center justify-center flex-1 h-0 align-middle">
                        <button
                          type="button"
                          className={`w-full border border-transparent rounded-none rounded-tr-lg p-1.5
                              flex items-center justify-center text-sm focus:outline-none focus:z-10 focus:ring-2
                              font-medium ${get(dataMap, `${primary.type}.color`)}
                              hover:${get(dataMap, `${primary.type}.color`)}
                              focus:ring-${get(dataMap, `${primary.type}.color`)}`}
                          onClick={() => {
                            setShow(false);
                            if (primary.onClick) primary.onClick();
                          }}
                        >
                          {primary.label}
                        </button>
                      </div>
                    )}

                    {secondary && (
                      <div className="flex items-center justify-center flex-1 h-0 align-middle">
                        <button
                          type="button"
                          className={`w-full border border-transparent rounded-none rounded-tr-lg p-1.5
                              flex items-center justify-center text-sm focus:outline-none focus:z-10 focus:ring-2
                              font-medium ${get(dataMap, `${secondary.type}.color`)}
                              hover:${get(dataMap, `${secondary.type}.color`)}
                              focus:ring-${get(dataMap, `${secondary.type}.color`)}`}
                          onClick={() => {
                            setShow(false);
                            if (secondary.onClick) secondary.onClick();
                          }}
                        >
                          {secondary.label}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

Notification.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  timeout: PropTypes.number,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
  primary: PropTypes.shape({
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger']),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
  }),
  secondary: PropTypes.shape({
    type: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'default']),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
  }),
  variant: PropTypes.oneOf(['topright', 'bottomleft']),
};

Notification.defaultProps = {
  type: 'info',
  timeout: 3000,
  variant: 'bottomleft',
};

export const triggerToast = arg => {
  const { setting = {}, type, ...props } = arg;
  const defaultSettings = {
    theme: 'light',
    icon: false,
    closeButton: false,
    hideProgressBar: true,
    autoClose: 5000,
    ...setting,
    position: 'bottom-right',
  };

  toast(<Notification {...props} />, { ...defaultSettings });
};

triggerToast.propTypes = {
  setting: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'info', 'warning', 'danger', 'default', 'processing']),
  message: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
    summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  }).isRequired,
  actions: PropTypes.any,
  isAnimate: PropTypes.bool,
  showBorder: PropTypes.bool,
  closeToast: PropTypes.func,
};

export default memo(Notification);
