/*
 * GetHelp Messages
 *
 * This contains all the text for the GetHelp component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.GetHelp';

export default defineMessages({
  report_an_issue: {
    id: `${scope}.report_an_issue`,
    defaultMessage: 'Report an Issue',
  },
  request_a_feature: {
    id: `${scope}.request_a_feature`,
    defaultMessage: 'Request a Feature',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Save Changes',
  },
  current_pass: {
    id: `${scope}.current_pass`,
    defaultMessage: 'Current Password*',
  },
  change_password: {
    id: `${scope}.change_password`,
    defaultMessage: 'Change Password',
  },
  new_pass: {
    id: `${scope}.new_pass`,
    defaultMessage: 'New Password*',
  },
  retype_new_pass: {
    id: `${scope}.retype_new_pass`,
    defaultMessage: 'Retype New Password*',
  },
  eight_character: {
    id: `${scope}.eight_characters`,
    defaultMessage: 'At least 8 characters',
  },
  uppercase_letters: {
    id: `${scope}.uppercase_letters`,
    defaultMessage: 'Use at least one uppercase and lowercase letters',
  },
  special_characters: {
    id: `${scope}.special_characters`,
    defaultMessage: 'At least one special character, e.g., ! @ # ? ]',
  },
  pass_dont_match: {
    id: `${scope}.pass_dont_match`,
    defaultMessage: 'New and confirm passwords do not match',
  },
  success_message_title: {
    id: `${scope}.success_message_title`,
    defaultMessage: 'Password changed',
  },
  success_message_summary: {
    id: `${scope}.success_message_summary`,
    defaultMessage: 'Password changed',
  },
  error_message_title: {
    id: `${scope}.error_message_title`,
    defaultMessage: 'Error',
  },
  error_message_summary: {
    id: `${scope}.error_message_summary`,
    defaultMessage: 'Authentication failed. Please check your credentials and try again',
  },
});
