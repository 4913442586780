import React from 'react';
import { faBell, faUser, faKey, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import EnglishUS from '../../../../images/Languages/EnglishUS.svg';
export const options = [
  {
    label: <FormattedMessage {...messages?.profile} />,
    value: 'profile',
    icon: <FontAwesomeIcon icon={faUser} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages?.notification_preferences} />,
    value: 'notification',
    icon: <FontAwesomeIcon icon={faBell} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages?.change_password} />,
    value: 'password',
    icon: <FontAwesomeIcon icon={faKey} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages?.language_settings} />,
    value: 'language',
    icon: <img src={EnglishUS} alt="US flag" className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages?.timezone} />,
    value: 'timezone',
    icon: <FontAwesomeIcon icon={faGlobe} className="h-4 w-4" />,
  },
];
