import { takeLatest, put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { makeRequest, insertQuestion, updateQuestion, bulkImport } from './api';
// import { getQuestionList } from '../api';

import {
  FETCH_DROPDOWN_DATA,
  STORE_DROPDOWN_DATA,
  ERROR_ON_FETCH_QLV_DATA,
  INSERT_QUESTION,
  UPDATE_QUESTION,
  BULK_IMPORT,
  TYPE_UPLOAD_FILE,
} from './constant';
import { QUSETION_INSERTION_COMPLETE, ERROR_ON_QUESTION_INSERTION } from '../constants';
import postData from '../../../utils/postData';
import { axiosPostData } from '../../../utils/axiosPostData';
import { GLOBAL_ERROR } from '../../App/constants';

export function* fetchSaga({ callback = {} }) {
  try {
    const { data, request } = yield call(makeRequest, true);
    yield put({ type: STORE_DROPDOWN_DATA, payload: data, request });
    if (callback && callback.onSuccess) {
      yield call(callback.onSuccess, data);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== 'production' && console) {
      // eslint-disable-next-line no-console
      // console.log(error);
    }
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    Sentry.captureException(error);
    yield put({ type: ERROR_ON_FETCH_QLV_DATA, error });
  }
}

export function* insertQuestionSaga({ query = {}, callback = {} }) {
  try {
    yield call(insertQuestion, query, true);
    if (callback && callback.onSuccess) {
      yield put({
        type: QUSETION_INSERTION_COMPLETE,
        complete: 'insert',
      });
      yield call(callback.onSuccess);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      yield put({
        type: ERROR_ON_QUESTION_INSERTION,
        error: 'insert',
      });
    }
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    Sentry.captureException(error);
    yield put({ type: ERROR_ON_FETCH_QLV_DATA, error });
  }
}
export function* bulkInsertQuestionSaga({ query = {}, callback = {} }) {
  try {
    yield call(bulkImport, query, true);
    if (callback && callback.onSuccess) {
      yield put({
        type: QUSETION_INSERTION_COMPLETE,
        complete: 'insert',
      });
      yield call(callback.onSuccess);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      yield put({
        type: ERROR_ON_QUESTION_INSERTION,
        error: 'insert',
      });
    }
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    Sentry.captureException(error);
    yield put({ type: ERROR_ON_FETCH_QLV_DATA, error });
  }
}
export function* updateQuestionSaga({ query = {}, callback = {} }) {
  try {
    yield call(updateQuestion, query, true);
    if (callback && callback.onSuccess) {
      yield put({
        type: QUSETION_INSERTION_COMPLETE,
        complete: 'update',
      });
      yield call(callback.onSuccess);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      yield put({
        type: ERROR_ON_QUESTION_INSERTION,
        error: 'update',
      });
    }
    if (callback && callback.onError) {
      yield call(callback.onError, error);
    }
    Sentry.captureException(error);
    yield put({ type: ERROR_ON_FETCH_QLV_DATA, error });
  }
}

export function* uploadFileSaga(props) {
  const { payload, queryString, callback } = props;
  try {
    const uploadFilePayload = {
      file_type_id: payload?.additionalParameter?.file_type_id,
      original_name: payload?.file?.name,
      owner_id: payload?.additionalParameter?.owner_id,
      extension: payload?.file?.name.split('.').pop(),
    };

    const response = yield call(postData, {
      queryString,
      payload: uploadFilePayload,
      spreadPayload: true,
    });

    if (response && response?.data?.file_upload_file) {
      const fileUploadData = response?.data?.file_upload_file?.data;
      const URL = response?.data?.file_upload_file?.data?.url;
      const { url, ...newFileUploadData } = fileUploadData;

      const formData = new FormData();
      Object.keys(newFileUploadData).forEach(key => {
        formData.append(key, newFileUploadData[key]);
      });
      formData.append('file', payload?.file);
      yield call(axiosPostData, URL, formData);

      if (callback?.onSuccess && response?.data) {
        yield call(callback?.onSuccess, response?.data);
      }
    } else {
      throw Error('No Response from file upload api');
    }
  } catch (error) {
    // yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export default function* QuestionSaga() {
  yield takeLatest(FETCH_DROPDOWN_DATA, fetchSaga);
  yield takeLatest(INSERT_QUESTION, insertQuestionSaga);
  yield takeLatest(UPDATE_QUESTION, updateQuestionSaga);
  yield takeLatest(BULK_IMPORT, bulkInsertQuestionSaga);
  yield takeLatest(TYPE_UPLOAD_FILE, uploadFileSaga);
}
