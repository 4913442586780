import React, { memo, useState } from 'react';
import ContactInformation from './ContactInformation';
import RoleOrganizations from './RoleOrganizations';
import LastLogIn from './LastLogIn';
import UserDetails from './userDetails';
import ProfilePic from './ProfilePic';

export function Profile(userDetails) {
  const [editProfilePic, setEditProfilePic] = useState(false);

  return (
    <div className="p-6">
      {editProfilePic ? (
        <ProfilePic setEditProfilePic={setEditProfilePic} data={userDetails} />
      ) : (
        <div>
          <UserDetails data={userDetails} setEditProfilePic={setEditProfilePic} />
          <div className="mt-10">
            <ContactInformation userDetails={userDetails?.userDetails} />
          </div>
          <div className="mt-4">
            <RoleOrganizations userDetails={userDetails?.userDetails} />
          </div>
          <div className="mt-4">
            <LastLogIn />
          </div>
        </div>
      )}
    </div>
  );
}

Profile.propTypes = {};

export default memo(Profile);
