import {
  FETCH_DROPDOWN_DATA,
  STORE_DROPDOWN_DATA,
  INSERT_QUESTION,
  UPDATE_QUESTION,
  BULK_IMPORT,
  TYPE_UPLOAD_FILE,
} from './constant';
import { QUERY_UPLOAD_FILE } from './queries';

export function fetchDropdownData({ callback }) {
  return {
    type: FETCH_DROPDOWN_DATA,
    callback,
  };
}

export function insertCodingQuestion({ query, callback }) {
  return {
    type: INSERT_QUESTION,
    query,
    callback,
  };
}

export function bulkInsert({ query, callback }) {
  return {
    type: BULK_IMPORT,
    query,
    callback,
  };
}

export function updateCodingQuestion({ query, callback }) {
  return {
    type: UPDATE_QUESTION,
    query,
    callback,
  };
}

export function storeDropdownData({ payload, request }) {
  return {
    type: STORE_DROPDOWN_DATA,
    payload,
    request,
  };
}

export const uploadFileAction = ({ payload, callback }) => ({
  type: TYPE_UPLOAD_FILE,
  payload,
  callback,
  queryString: QUERY_UPLOAD_FILE,
  key: 'auth_update_user',
});
