import LoginPage from 'containers/auth/LoginPage/Loadable';
import LogoutPage from 'containers/auth/LogoutPage/Loadable';
import QuestionListView from 'containers/QuestionListView/Loadable';
import QuestionBank from 'containers/QuestionBank/Loadable';
import { Forbidden, Error, NotFound } from 'components/pages';
import AssesmentLibraryList from 'containers/AssessmentLibraryList/Loadable';
import AssesmentLibrary from 'containers/AssessmentLibraryView/Loadable';
import CatalogList from 'containers/CatalogList/Loadable';
import CmViewRequest from 'containers/QuestionBank/CMRequest/Loadable';
import QbmSmeViewRequest from 'containers/Sme/Loadable';
import QbmSmeReviewQuestionList from 'containers/SmeReviewQuestionList/Loadable';
import QbmSmeAddQuestion from 'containers/Sme/AddQuestion/Loadable';
import PdfReport from 'containers/PdfReport/Loadable';

export default [
  { key: 1, isAuthenticated: false, path: '/login', component: LoginPage, exact: true },
  { key: 3, isAuthenticated: false, path: '/logout', component: LogoutPage, exact: true },
  { key: 5, isAuthenticated: false, path: '/', component: LoginPage, exact: true },
  { key: 42, isAuthenticated: false, path: '/pdf-report', component: PdfReport, exact: true },
  {
    key: 7,
    isAuthenticated: true,
    path: '/question-bank/:qbid',
    component: QuestionListView,
    exact: true,
    skipTest: true,
  },
  { key: 6, isAuthenticated: true, path: '/question-bank', component: QuestionBank, exact: true, skipTest: true },
  {
    key: 10,
    isAuthenticated: true,
    path: '/question-bank/request/:qbid',
    component: CmViewRequest,
    exact: true,
    skipTest: true,
  },
  {
    key: 10,
    isAuthenticated: true,
    path: '/sme/request/:skid',
    component: QbmSmeAddQuestion,
    exact: true,
    skipTest: true,
  },
  { key: 7, isAuthenticated: true, path: '/library', component: AssesmentLibraryList, exact: true, skipTest: true },
  {
    key: 8,
    isAuthenticated: true,
    path: '/library/create',
    component: AssesmentLibrary,
    exact: true,
    skipTest: true,
  },
  {
    key: 9,
    isAuthenticated: true,
    path: '/library/edit/:aid',
    component: AssesmentLibrary,
    exact: true,
    skipTest: true,
  },
  {
    key: 12,
    isAuthenticated: true,
    path: '/sme/review-question-list',
    component: QbmSmeReviewQuestionList,
    exact: true,
    skipTest: true,
  },
  { key: 41, isAuthenticated: true, path: '/catalog', component: CatalogList, exact: true, skipTest: true },
  { key: 2, isAuthenticated: true, path: '/sme', component: QbmSmeViewRequest, exact: true, skipTest: true },
  { key: 19, isAuthenticated: true, path: '/forbidden', component: Forbidden, exact: true, skipTest: true },
  { key: 39, isAuthenticated: false, path: '/error', component: Error, exact: true, skipTest: true },
  { key: 40, isAuthenticated: false, component: NotFound, skipTest: true },
];
