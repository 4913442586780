import {
  GLOBAL_LOADING,
  GLOBAL_ERROR,
  INIT_CLIENT,
  CLEAR_ERROR,
  SAVE_STATE_ACTION,
  INIT_LOGIN_CLIENT,
  FETCH_USER_DATA,
  STORE_USER_DATA,
  UPDATE_TIME_ZONE,
} from './constants';

/**
 * Global Loading state, this action starts the request saga
 *
 * @return {object} An action object with a type of GLOBAL_LOADING
 */
export function loading() {
  return {
    type: GLOBAL_LOADING,
  };
}

/**
 * Dispatched when there is fatal non-recoverable error
 *
 * @param  {object} e The error object
 *
 * @return {object}      An action object with a type of GLOBAL_ERROR passing the error
 */
export function error(e) {
  return {
    type: GLOBAL_ERROR,
    e,
  };
}

export function clearErrorAction({ history }) {
  return {
    type: CLEAR_ERROR,
    history,
  };
}

export function saveAction({ key, data }) {
  return {
    type: SAVE_STATE_ACTION,
    key,
    data,
  };
}
export function initializeAction() {
  return {
    type: INIT_CLIENT,
    loading: true,
  };
}

export function fetchUserData() {
  return {
    type: FETCH_USER_DATA,
    loading: true,
  };
}
export function storeUserData(payload) {
  return {
    type: STORE_USER_DATA,
    payload,
  };
}

export function initLoginAction(history, access_token, errorCallback) {
  return {
    type: INIT_LOGIN_CLIENT,
    loading: true,
    history,
    access_token,
    errorCallback,
  };
}
export function refetchUserData() {
  return {
    type: FETCH_USER_DATA,
  };
}

export function updateTimeZone({ timezone }) {
  return {
    type: UPDATE_TIME_ZONE,
    timezone,
  };
}
