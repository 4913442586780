/*
 *
 * Question List View reducer
 *
 */
import produce from 'immer';
import { FETCH_DROPDOWN_DATA, STORE_DROPDOWN_DATA, ERROR_ON_FETCH_QLV_DATA } from './constant';

export const initialState = {
  request: {
    error: null,
    isLoading: false,
  },
  level: [],
  skills: [],
  bloomsTaxonamy: [],
  languages: [],
  status: [],
  createdBy: [],
};

/* eslint-disable default-case, no-param-reassign */
const questionListViewReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_DROPDOWN_DATA:
        draft.request.isLoading = true;
        draft.request.error = null;
        break;

      case STORE_DROPDOWN_DATA:
        draft.request.isLoading = false;
        draft.level = action.payload.level;
        draft.skills = action.payload.skills;
        draft.bloomsTaxonamy = action.payload.bloomsTaxonamy;
        draft.languages = action.payload.languages;
        draft.status = action.payload.status;
        draft.createdBy = action.payload.createdBy;
        break;

      case ERROR_ON_FETCH_QLV_DATA: {
        draft.request.isLoading = false;
        draft.request.error = action.error;
        break;
      }
      default:
        return draft;
    }
    return draft;
  });

export default questionListViewReducer;
