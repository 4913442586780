/**
 *
 * ProfilePopover
 *
 */

import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useMemo, memo, Fragment } from 'react';
import { getDisplayName } from 'utils/auth';
import { toTitleCase } from 'utils/helpers';
import { NavigationIcon } from '../NavigationIcon';
import ProfilePopoverContent from './ProfilePopoverContent';

export function ProfilePopover(props) {
  const { expanded, showAccountSettings, accountSettings, userDetails } = props;
  const user = JSON.parse(localStorage.getItem('tv.ae'))?.user;
  const userName = useMemo(() => getDisplayName(userDetails), [userDetails]);
  const role = localStorage.getItem('tv.ae') ? JSON.parse(localStorage.getItem('tv.ae')).role : ['Content Manager'];
  return (
    <Popover className="relative">
      <Popover.Button className="outline-none">
        <NavigationIcon
          label={userName}
          isChevronRight
          expanded={expanded}
          additionalContent={role}
          customTooltipContent="Profile"
          user={user}
          userDetails={userDetails}
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className={classNames('absolute bg-white bottom-5', expanded ? 'left-44' : 'left-16')}>
          <ProfilePopoverContent
            name={userName}
            user_role={toTitleCase(role)}
            showAccountSettings={showAccountSettings}
            accountSettings={accountSettings}
            userDetails={userDetails}
          />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

ProfilePopover.propTypes = {};

export default memo(ProfilePopover);
