import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectQuestionListView = state => state?.questionlistview || initialState;

const selectQuestionListData = createSelector(selectQuestionListView, state => state);
const selectQuestionListViewRequest = createSelector(selectQuestionListView, state => state.request);

const selectReviewRequestId = createSelector(selectQuestionListView, state => state.selectedRequestIdsForReview);

const selectedNewAssignTo = createSelector(selectQuestionListView, state => state.setNewAssignTo);

export {
  selectQuestionListData,
  selectQuestionListView,
  selectQuestionListViewRequest,
  selectedNewAssignTo,
  selectReviewRequestId,
};
