import Auth, { apolloClientWrapper } from 'utils/auth';
import { getAuth } from 'firebase/auth';
import { getApps, initializeApp } from 'firebase/app';
import { eventChannel } from 'redux-saga';
import { GET_USER_PROFILE_DETAILS, GET_TIMEZONES } from './queries';

export const createEventChannel = () =>
  eventChannel(emitter => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => emitter({ user }));
    return () => unsubscribe();
  });

export async function init() {
  if (!getApps().length) {
    const config = {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_API_DOMAIN,
    };
    initializeApp(config);
  }
}

export const userProfileDetails = async () =>
  new Promise((resolve, reject) => {
    try {
      apolloClientWrapper(GET_USER_PROFILE_DETAILS)
        .then(response => {
          resolve(response.data);
          const auth = getAuth();
          const lastLoginTime = auth.currentUser.metadata.lastSignInTime;
          localStorage.setItem('tv.lastLoginTime', JSON.stringify(lastLoginTime));
        })
        .catch(ex => {
          reject(ex);
        });
    } catch (error) {
      reject(error);
    }
  });

export const fetchTimeZoneDate = async (query = {}, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.query({
    query: GET_TIMEZONES,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: query,
  });

  return {
    data,
  };
};
