/* eslint-disable no-param-reassign */
import React from 'react';
import {
  ROLE_CONTENT_MANAGER,
  ROLE_MASTER_CONTENT_MANAGER,
  ROLE_TENANT_ADMIN,
  ROLE_RECRUITER,
  ROLE_MASTER_RECRUITER,
} from 'utils/roles';
import Auth from 'utils/auth';
import {
  faUserGear,
  faBuildingCircleArrowRight,
  faUserMagnifyingGlass,
  faUserGroup,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { filterItems } from '../SideNavigation/NavModules/items';
import messages from './messages';

export const getItems = () => {
  const roles = Auth.roles || [];
  const items = [
    {
      icon: faUserGear,
      name: <FormattedMessage {...messages?.adminConsole} />,
      summary: <FormattedMessage {...messages?.adminConsoleSummary} />,
      route: process.env.REACT_APP_ADMIN_URL,
      roles: [ROLE_TENANT_ADMIN],
    },
    {
      icon: faUserGroup,
      name: <FormattedMessage {...messages?.workflowLabel} />,
      summary: <FormattedMessage {...messages?.workflowSummary} />,
      route: process.env.WORKFLOW_URL,
      roles: [ROLE_TENANT_ADMIN, ROLE_CONTENT_MANAGER, ROLE_MASTER_CONTENT_MANAGER],
    },
    {
      icon: faUserMagnifyingGlass,
      name: <FormattedMessage {...messages?.recruit} />,
      summary: <FormattedMessage {...messages?.recruitSummary} />,
      route: process.env.RECRUIT_URL,
      roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER],
    },
    {
      icon: faBuildingCircleArrowRight,
      name: <FormattedMessage {...messages?.campusLabel} />,
      summary: <FormattedMessage {...messages?.campusSummary} />,
      route: '/campus',
      roles: [ROLE_TENANT_ADMIN, ROLE_RECRUITER, ROLE_MASTER_RECRUITER],
    },
  ];
  return filterItems({ items, roles });
};
