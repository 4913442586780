/**
 *
 * Pagination
 *
 */

import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { get, ceil } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectSaved } from 'containers/App/selectors';
import { saveAction } from 'containers/App/actions';
import messages from './messages';

export function Pagination(props) {
  const { pageSize, onCurrentChange, onSizeChange, currentPage, saved, total, summary, saveSize, reset } = props;
  const [currentSize] = useState(pageSize || parseInt(get(saved, 'pageSize'), 10) || 25);
  const [current, setCurrent] = useState(+currentPage);
  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (onSizeChange) onSizeChange(currentSize);
    if (saveSize) saveSize(currentSize);
  }, [currentSize]);

  useEffect(() => {
    if (onCurrentChange) onCurrentChange(current, pageSize);
  }, [current]);

  useEffect(() => {
    if (reset) setCurrent(1);
  }, [reset]);
  const pages = ceil(total / pageSize);

  return (
    <div className="px-4 py-3 flex items-center justify-between sm:px-6 ">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          onClick={() => setCurrent(current - 1)}
          className="relative inline-flex items-center px-1 py-0
               rounded-md text-sm font-medium text-gray-500
               hover:bg-neutral-100 active:bg-neutral-200 focus:outline-black"
        >
          <ChevronLeftIcon className={`h-7 w-13  ${current <= 1 ? 'fill-gray-300' : ''}`} aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={() => setCurrent(currentPage + 1)}
          className="relative inline-flex items-center px-1 py-0
             rounded-md text-sm font-medium text-gray-500
             hover:bg-neutral-100 active:bg-neutral-200 focus:outline-black"
        >
          <ChevronRightIcon className={`h-7 w-13 ${current >= pages ? 'fill-gray-300' : ''}`} aria-hidden="true" />
        </button>
      </div>
      <div className="hidden sm:flex sm:items-center float-right right-7 absolute">
        <div className="lg:visible sm:invisible md:invisible invisible">
          {summary && (
            <p className="w-55 h-21 text-sm text-gray-600 pr-2">
              <span className="font-normal">{total ? Math.max((current - 1) * pageSize + 1, 1) : 0}</span> -{' '}
              <span className="font-normal">{current * pageSize > total ? total : current * pageSize}</span> of{' '}
              <span className="font-normal">{total}</span>
            </p>
          )}
        </div>
        <div>
          <button
            type="button"
            onClick={() => setCurrent(current - 1)}
            disabled={current <= 1}
            className={`relative inline-flex items-center px-2 py-2 rounded-md
                     text-sm font-medium text-gray-500 hover:bg-neutral-100 active:bg-neutral-200 focus:outline-black
                  `}
          >
            <span className="sr-only">
              <FormattedMessage {...messages.previous} />
            </span>
            <ChevronLeftIcon className={`h-7 w-13  ${current <= 1 ? 'fill-gray-300' : ''}`} aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={() => setCurrent(current + 1)}
            disabled={current >= pages}
            className={`relative inline-flex items-center px-2 py-2 rounded-md
                     text-sm font-medium text-gray-500 hover:bg-neutral-100 active:bg-neutral-200 focus:outline-black
                  `}
          >
            <span className="sr-only">
              <FormattedMessage {...messages.next} />
            </span>
            <ChevronRightIcon className={`h-7 w-13 ${current >= pages ? 'fill-gray-300' : ''}`} aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  total: PropTypes.number,
  saved: PropTypes.object,
  saveSize: PropTypes.func,
  type: PropTypes.string,
  onSizeChange: PropTypes.func,
  onCurrentChange: PropTypes.func,
  pageSize: PropTypes.number,
  summary: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = createStructuredSelector({
  saved: makeSelectSaved(),
});

export function mapDispatchToProps(dispatch) {
  return {
    saveSize: (key, data) => dispatch(saveAction({ key, data })),
    dispatch,
  };
}

Pagination.defaultProps = {
  summary: true,
  pageSize: 10,
  total: 10,
  currentPage: 1,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Pagination);
