export function validatePassword(password) {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.{8,})/;

  if (!passwordRegex.test(password)) {
    const errors = {
      length: password.length < 8 ? 'Password must be at least 8 characters.' : '',
      uppercase: /[A-Z]/.test(password) ? '' : 'Password must contain at least one uppercase letter.',
      lowercase: /[a-z]/.test(password) ? '' : 'Password must contain at least one lowercase letter.',
      specialChar: /[!@#$%^&*]/.test(password)
        ? ''
        : 'Password must contain at least one special character (!@#$%^&*).',
    };

    return {
      valid: false,
      errors,
    };
  }

  return { valid: true };
}
