/* eslint-disable max-len */
/**
 *
 * Dropdown
 *
 */

import React, { memo, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { get, map } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function Dropdown(props) {
  const { items, optionLabel, location, customControl, buttonIcon } = props;
  return (
    <Menu as="div" className="relative inline-block text-center">
      <div>
        {customControl || (
          <>
            {props.children ? (
              <Menu.Button
                className="flex items-center rounded-full hover:text-gray-600
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                 focus:ring-indigo-500"
              >
                <span className="sr-only">Open options</span>
                {props.children}
              </Menu.Button>
            ) : (
              <Menu.Button
                className="inline-flex justify-center w-full py-2 text-sm
                font-normal border-gray-300 text-white-700
                focus:outline-none"
              >
                {optionLabel && <p className="mr-2">{optionLabel}</p>}
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
            )}
          </>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            location === 'top' ? 'bottom-0 mb-10  origin-top-right' : 'origin-bottom-right',
            ' absolute right-0 mt-2 rounded-md z-50',
            buttonIcon ? 'w-32' : 'w-56',
            'shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100',
          )}
        >
          {map(items, ({ group, id }) => (
            <div className="py-1" key={id}>
              {map(group, (item, index) => (
                <Menu.Item key={get(group, 'id') || index}>
                  {({ active }) => (
                    <>
                      {item.Content ? (
                        <item.Content active={active} />
                      ) : (
                        <button
                          type="button"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            item?.label === 'Delete' ? `text-red-700` : '',
                            'block px-2 py-2 text-sm',
                            buttonIcon ? 'w-full text-left hover:bg-gray-100' : '',
                            !item.onClick ? 'disabled text-zinc-200' : '',
                          )}
                          onClick={item.onClick ? item.onClick : () => {}}
                        >
                          {item.Icon && <item.Icon className="inline w-5 h-5" />}
                          <span className="pl-2">{item.label}</span>
                        </button>
                      )}
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

Dropdown.propTypes = {
  items: PropTypes.array,
  location: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.element,
  optionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  customControl: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Dropdown.defaultProps = {
  location: 'bottom',
};

export default memo(Dropdown);
