/**
 *
 * TopNavBar
 *
 */

import React, { memo } from 'react';
import Breadcrumb from './Breadcrumb';

export function TopNavBar() {
  return (
    <div className="flex items-center px-3 py-5 pb-0 bg-white">
      <Breadcrumb />
    </div>
  );
}

export default memo(TopNavBar);
