/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
// import { fromPromise } from 'apollo-link';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'; // todo make async
import { onError } from 'apollo-link-error';

import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import localforage from 'localforage';

class Client {
  constructor() {
    if (!Client.instance) {
      Client.instance = this;
    }
  }

  get client() {
    return this._client;
  }

  get persistor() {
    return this._persistor;
  }

  async createClient({ graphToken, errorCallback, role }) {
    if (this._client) return this._client;
    const token = await graphToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      'x-hasura-role': role,
    };
    const httpLink = createHttpLink({
      uri: `/v1/graphql`,
      headers,
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line no-restricted-syntax
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case 'invalid-jwt':
            case 'start-failed':
              if (errorCallback) {
                errorCallback();
              }
              break;
            // @todo implement refresh token
            // return fromPromise(
            //   this.getNewToken(token).catch(error => {
            //     // Handle token refresh errors e.g clear stored tokens, redirect to login
            //   }),
            // )
            //   .filter(value => Boolean(value))
            //   .flatMap(accessToken => {
            //     const oldHeaders = operation.getContext().headers;
            //     // modify the operation context with a new token
            //     operation.setContext({
            //       headers: {
            //         ...oldHeaders,
            //         authorization: `Bearer ${accessToken}`,
            //       },
            //     });
            //
            //     // retry the request, returning the new observable
            //     return forward(operation);
            //   });
            default:
              if (console) {
                console.warn(`[GraphQL error]: Message: ${err.message}, Location: ${err.extensions.code}`);
              }
          }
        }
      }
      if (networkError && console) {
        console.error(`[Network error]: ${networkError}`);
      }
    });
    const wss = process.env.HASURA_WS || 'wss://graphql.talview.com/v1/graphql';
    const wssClient = new SubscriptionClient(wss, {
      reconnect: true,
      connectionParams: {
        headers,
      },
    });

    const wsLink = new WebSocketLink(wssClient);
    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    const cache = new InMemoryCache();
    this._persistor = new CachePersistor({
      cache,
      maxSize: false,
      debounce: 500,
      storage: new LocalForageWrapper(localforage),
    });
    await localforage.setDriver([localforage.INDEXEDDB, localforage.LOCALSTORAGE]);
    this._client = new ApolloClient({
      link: errorLink.concat(link),
      cache,
    });
    return this._client;
  }

  async clearCache() {
    if (this._persistor) {
      return this._persistor.purge();
    }
    return Promise.resolve();
  }

  removeClient() {
    if (this._client) {
      this._client.stop();
      return this.client.clearStore();
    }
    return true;
  }
}
const instance = new Client();

export default instance;
