import { takeLatest, call, put, all, fork, takeEvery } from 'redux-saga/effects';
import postData from 'utils/postData';
import { GLOBAL_ERROR } from 'containers/App/constants';
import { get } from 'lodash';
import Auth from 'utils/auth';
import { axiosPostData } from 'utils/axiosPostData';
import fetchData from 'utils/fetchData';
import { FETCH_FILE_URL_QUERY, UPDATE_PASSWORD_QUERY, UPDATE_USER_QUERY } from './queries';
import { FETCH_FILE_URL, UPDATE_PASSWORD, UPDATE_USER } from './constants';

export const ongoingTasks = [];

export const getClient = async () => {
  const client = await Auth.client();
  return client;
};

export function* uploadFileSaga(props) {
  const { payload, queryString, callback } = props;
  try {
    const uploadFilePayload = {
      file_type_id: payload?.additionalParameter?.file_type_id,
      original_name: payload?.file?.name,
      owner_id: payload?.additionalParameter?.owner_id,
      extension: payload?.file?.name.split('.').pop(),
    };

    const response = yield call(postData, {
      queryString,
      payload: uploadFilePayload,
      spreadPayload: true,
    });

    if (response && response?.data?.file_upload_file) {
      const fileUploadData = response?.data?.file_upload_file?.data;
      const URL = response?.data?.file_upload_file?.data?.url;
      const { url, ...newFileUploadData } = fileUploadData;

      const formData = new FormData();
      Object.keys(newFileUploadData)?.forEach(key => {
        formData.append(key, newFileUploadData[key]);
      });
      formData.append('file', payload?.file);
      yield call(axiosPostData, URL, formData);

      if (callback?.onSuccess && response?.data) {
        yield call(callback?.onSuccess, response?.data);
      }
    } else {
      throw Error('No Response from file upload api');
    }
  } catch (error) {
    // yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* fetchFileUrlSaga(props) {
  const { queryVariables, callback } = props;

  try {
    const response = yield call(fetchData, {
      queryString: FETCH_FILE_URL_QUERY,
      queryVariables,
      queryKey: 'file_download_file',
    });

    if (response && response?.resource_url && callback?.onSuccess) {
      yield call(callback?.onSuccess, response);
    }
  } catch (error) {
    // yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}

export function* updateUserSaga(props) {
  const { payload, callback } = props;
  try {
    const response = yield call(postData, {
      queryString: UPDATE_USER_QUERY,
      payload,
    });

    if (response && response?.data && callback?.onSuccess) {
      yield call(callback?.onSuccess, response);
    }
  } catch (error) {
    // yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}
export function* updatePasswordSaga(props) {
  const { payload, callback } = props;
  try {
    const response = yield call(postData, {
      queryString: UPDATE_PASSWORD_QUERY,
      payload,
    });

    if (response && response?.data && callback?.onSuccess) {
      yield call(callback?.onSuccess, response?.data?.auth_update_user_password);
    }
  } catch (error) {
    // yield call(catchError, error, true);
    if (callback && callback?.onError) {
      yield call(callback?.onError, error);
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: GLOBAL_ERROR, error });
    }
  }
}
export function* actionWatcher() {
  yield takeLatest(UPDATE_USER, updateUserSaga);
}
export function* fetchFileUrlWatcher() {
  yield takeEvery(FETCH_FILE_URL, fetchFileUrlSaga);
}
export function* updatePasswordWatcher() {
  yield takeLatest(UPDATE_PASSWORD, updatePasswordSaga);
}

export default function* actionSaga() {
  yield all([fork(actionWatcher), fork(fetchFileUrlWatcher), fork(updatePasswordWatcher)]);
}
