/**
 *
 * Tooltip
 *
 */

import { autoUpdate, flip, offset, shift, useFloating, arrow, FloatingArrow } from '@floating-ui/react';
import React, { useRef } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function Tooltip(props) {
  const { id, anchor, placement = 'bottom', offsetValue = 10, arrowColor = 'black', noArrow = false } = props;
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    elements: {
      reference: anchor,
    },
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      offset(offsetValue),
      flip(),
      shift({ padding: 10 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  return ReactDOM.createPortal(
    <div ref={refs.setFloating} id={id} style={floatingStyles} className="z-50">
      {props.children}
      {!noArrow && (
        <FloatingArrow
          ref={arrowRef}
          context={context}
          fill={arrowColor}
          stroke="rgba(229, 231, 235, 1)"
          strokeWidth={1}
          gap="10px"
        />
      )}{' '}
    </div>,
    document.getElementById('tooltip-container'),
  );
}

Tooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  anchor: PropTypes.object.isRequired,
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
  ]),
  offsetValue: PropTypes.number,
};

export default Tooltip;
