import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import getDisplayName from 'utils/getDisplayName';
import classNames from 'classnames';
import { get } from 'lodash';
import Avatar from '../Avatar/NewAvatar';
import Tooltip from '../ToolTip';

const variants = {
  light: {
    text: 'text-white',
  },
};

const sizes = {
  xs: {
    wrapper: 'space-x-2',
    title: 'text-xs',
    description: 'text-xs',
  },
  sm: {
    wrapper: 'space-x-3',
    title: 'text-sm',
    description: 'text-xs',
  },
  md: {
    wrapper: 'space-x-4',
    title: 'text-base',
    description: 'text-sm',
  },
  lg: {
    wrapper: 'space-x-5',
    title: 'text-lg',
    description: 'text-base',
  },
};

const borders = {
  'border-none': '',
  default: 'border border-gray-300 shadow-sm',
  'border-round': 'border border-gray-300 shadow-sm rounded-lg',
};

const User = props => {
  const {
    data: userData,
    showEmail,
    size,
    border,
    contentAlign,
    hideImage,
    avatarProps,
    showToolTip,
    additionalContent,
    isTitleView,
    isInTooltip,
    variant,
  } = props;
  const [anchor, setAnchor] = useState(null);
  const [tooltip, toggleTooltip] = useState(false);

  const data = useMemo(() => userData || {}, [userData]);

  const fullName = useMemo(() => getDisplayName(data?.userDetails), [data?.userDetails]);
  if (!userData) {
    return <div>-</div>;
  }

  return (
    <div className={classNames('flex flex-1 items-center', sizes[size]?.wrapper || sizes.sm.wrapper, borders[border])}>
      {hideImage ? <></> : <Avatar data={data?.userDetails} size="lg" {...avatarProps} />}
      <div
        ref={setAnchor}
        onMouseEnter={() => toggleTooltip(() => true)}
        onMouseLeave={() => toggleTooltip(() => false)}
        className={classNames('flex flex-1 flex-col max-w-max', isInTooltip ? 'w-max' : 'w-0')}
      >
        <p
          className={classNames(
            contentAlign === 'inline' ? 'mr-2' : '',
            isTitleView && 'font-medium text-gray-900',
            !isInTooltip && 'truncate',
          )}
        >
          <span
            className={classNames(
              get(sizes[size], 'title') || get(sizes, 'sm.title'),
              'font-normal',
              get(variants[variant], 'text') || 'text-gray-900',
            )}
          >
            {data?.name || fullName || data?.username || data?.email || data?.external_id || data?.id || '-'}
          </span>
        </p>
        {additionalContent ? (
          <div
            className={classNames(
              typeof additionalContent === 'string' && 'mt-1',
              sizes[size]?.description || sizes.sm.description,
              contentAlign === 'inline' ? 'leading-7' : '',
              isTitleView ? 'font-normal text-sm text-gray-600' : 'text-primary-400',
            )}
          >
            {additionalContent}
          </div>
        ) : (
          <></>
        )}
        {showEmail ? (
          <div
            className={classNames(
              typeof showEmail === 'string' && 'mt-1',
              sizes[size]?.description || sizes.sm.description,
              contentAlign === 'inline' ? 'leading-7' : '',
              isTitleView ? 'font-normal text-sm text-gray-600' : 'text-primary-400',
            )}
          >
            {userData.email || userData.username}
          </div>
        ) : (
          <></>
        )}
        {showToolTip && tooltip ? (
          <Tooltip id={`tooltip-${data?.id}`} anchor={anchor} placement="bottom" offsetValue={10}>
            <div className={classNames('px-2 py-1 bg-gray-900 text-white text-[10px] rounded')}>
              <div>{getDisplayName(data, false, true)}</div>
              <div>{data?.email}</div>
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

User.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    external_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  border: PropTypes.oneOf(['border-none', 'default', 'border-round']),
  hideImage: PropTypes.bool,
  showEmail: PropTypes.bool,
  avatarProps: PropTypes.shape({
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    shape: PropTypes.oneOf(['rounded-full', 'rounded-md']),
    background: PropTypes.string,
    isInitials: PropTypes.bool,
    textColor: PropTypes.string,
  }),
  showToolTip: PropTypes.bool,
  additionalContent: PropTypes.any,
};

User.defaultProps = {
  data: {},
  size: 'sm',
  hideImage: false,
  avatarProps: {
    isInitials: false,
  },
  showToolTip: false,
};

export default User;
