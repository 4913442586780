/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { useEffect, useMemo, useState } from 'react';
import { GrowthBookProvider, useFeatureIsOn } from '@growthbook/growthbook-react';
import PropTypes from 'prop-types';
import Mixpanel from 'mixpanel-browser';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import useFetchProfilePicUrl from 'hooks/useFetchFileUrl';
import { compose } from 'redux';
import { get, map } from 'lodash';
import ReactGA from 'react-ga';
import { TopNavBar } from 'components/base/TopNavBar';
import { SideNavigation } from 'components/base/SideNavigation';
import Maintenance from 'components/pages/Maintenance';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Loading from 'components/pages/Loading';
import { UserProvider } from 'contexts/UserContext';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import '../../base.css';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { clearErrorAction, fetchUserData, initializeAction, initLoginAction } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectLocation, makeSelectApp } from './selectors';
import routeMap from './routeMap';
import { growthbook } from '../../utils/growthbook';
import { getItems } from '../../components/base/SideNavigation/NavModules/items';

export function App(props) {
  useInjectReducer({ key: 'App', reducer });
  useInjectSaga({ key: 'App', saga });
  const { clearError, initialize, page, initLogin, fetchUserDetails } = props;
  const history = useHistory();
  const location = useLocation();
  const { search } = useLocation();
  const qp = new URLSearchParams(search);
  const access_token = qp.get('accessToken');
  const mixPanelEnabled = useFeatureIsOn(growthBookFeatureFlags.MIX_PANEL_ENABLED);

  if (location.pathname.includes('/logout')) {
    localStorage.removeItem('tv.ae');
  }

  const { error, loading, client, user, userDetails } = page || {};
  // STEP #2
  useEffect(() => {
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      clearError(history);
    }
  }, [error]);

  // STEP #4
  useEffect(() => {
    if (initialize) initialize();
    initLogin(access_token);
    initMixPanel();
    initGA();
  }, [access_token]);

  useEffect(() => {
    if (user?.userId) configureGrowthBook(user.userId, user.organization);

    if (user?.isLoggedIn && !userDetails.tenant) {
      fetchUserDetails();
    }
  }, [page]);
  const { url } = useFetchProfilePicUrl(userDetails?.profile_pic_file_id);
  const userWithProfilePic = useMemo(() => ({ ...userDetails, url }), [url, userDetails]);
  const initMixPanel = () => {
    Mixpanel.init(process.env.MIXPANEL_TOKEN || `a95719b74f5623705060efcb54b876ba`, {
      ignore_dnt: true,
      opt_out_tracking_by_default: mixPanelEnabled,
      loaded(mixpanel) {
        growthbook.setAttributes({
          ...growthbook.getAttributes(),
          id: mixpanel.get_distinct_id(),
        });
      },
    });
    if (mixPanelEnabled) {
      Mixpanel.opt_in_tracking();
    } else {
      Mixpanel.opt_out_tracking();
    }
  };

  const initGA = () => {
    if (ReactGA) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  };

  const configureGrowthBook = (userId, tenantId) => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      user_id: userId,
      tenant_id: Number(tenantId),
    });
    growthbook.loadFeatures({ autoRefresh: true });
  };

  if (process.env.MAINTAINANCE_MODE === 'true') {
    return <Maintenance />;
  }

  return (
    <ApplicationProvider>
      <GrowthBookProvider growthbook={growthbook}>
        <UserProvider client={client} user={user}>
          {loading ? (
            <Loading />
          ) : (
            <div className="flex w-full h-full overflow-hidden">
              {userDetails && !location.pathname.includes('/logout') && (
                <div className="absolute z-50 h-full">
                  <SideNavigation iconArray={getItems()} userDetails={userWithProfilePic} />
                </div>
              )}
              <div
                className={`flex flex-col ${
                  !location.pathname.includes('/logout') && 'ml-[70px]'
                } flex-1 overflow-hidden`}
              >
                {user?.isLoggedIn && !user?.isAnonymous && !location.pathname.includes('/logout') && <TopNavBar />}
                <Switch>
                  {map(routeMap, ({ isAuthenticated, path, component, exact, key }) =>
                    isAuthenticated ? (
                      <AuthenticatedRoute exact={exact} path={path} component={component} key={key} error={error} />
                    ) : (
                      <Route exact={exact} path={path} component={component} key={key} />
                    ),
                  )}
                </Switch>
              </div>
            </div>
          )}
        </UserProvider>
      </GrowthBookProvider>
    </ApplicationProvider>
  );
}
App.propTypes = {
  clearError: PropTypes.func,
  initialize: PropTypes.func,
  page: PropTypes.object,
  initLogin: PropTypes.func,
  //  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

App.defaultProps = {
  page: {},
};

const mapStateToProps = createStructuredSelector({
  page: makeSelectApp(),
  location: makeSelectLocation(),
});

export function mapDispatchToProps(dispatch) {
  return {
    initialize: () => {
      dispatch(initializeAction());
    },
    initLogin: access_token => dispatch(initLoginAction(access_token)),
    fetchUserDetails: () => dispatch(fetchUserData()),
    clearError: history => dispatch(clearErrorAction({ history })),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
