import React, { useMemo } from 'react';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function LastLogIn() {
  const data = localStorage.getItem('tv.ae') && JSON.parse(localStorage.getItem('tv.lastLoginTime'));
  const lastLogInTime = useMemo(() => {
    const parsedDate = DateTime.fromRFC2822(data);

    const formattedDate = parsedDate.toFormat('MMMM d yyyy, h:mm a');
    return formattedDate;
  }, [data]);

  return (
    <div className="p-2 bg-gray-100 border rounded-md">
      <div className="flex items-center text-gray-500 ">
        <FontAwesomeIcon icon={faClock} className="w-4 pr-1 rounded-full" />
        <span className="ml-1 text-base">
          <FormattedMessage {...messages.lastLogIn} />
          <span className="ml-0.5">{lastLogInTime}</span>
        </span>
      </div>
    </div>
  );
}

export default LastLogIn;
