import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import Button from 'components/base/Button';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changePassword } from 'containers/Action/actions';
import messages from './messages';
import { validatePassword } from './utils';
import ChangePasswordErrors from './Errors';
import { triggerToast } from '../../../../Notification';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [currentType, setCurrentType] = useState('password');
  const [newType, setNewType] = useState('password');
  const [reType, setReType] = useState('password');
  const [loading, toggleLoading] = useState(false);

  const showNotification = message => {
    triggerToast({
      variant: message.variant,
      setting: { position: 'top-right' },
      message,
    });
  };
  const samePasswordError = useMemo(
    () => retypePassword && newPassword && retypePassword !== newPassword,
    [retypePassword, newPassword],
  );

  const newPasswordError = useMemo(() => newPassword && validatePassword(newPassword), [newPassword]);

  const handleNewPasswordEyeClick = (value, setValue) => {
    if (value === 'password') {
      setValue('text');
    } else {
      setValue('password');
    }
  };

  const handleSubmit = () => {
    if (samePasswordError) {
      return;
    }
    toggleLoading(true);
    const payload = { current_password: currentPassword, new_password: newPassword };
    dispatch(
      changePassword({
        payload,
        callback: {
          onSuccess: res => {
            if (res?.success) {
              toggleLoading(false);
              showNotification({
                variant: 'success',
                title: <FormattedMessage {...messages.success_message_title} />,
                summary: <FormattedMessage {...messages.success_message_summary} />,
              });
              history.push('/logout');
            } else {
              toggleLoading(false);
            }
          },
          onError: () => {
            toggleLoading(false);
          },
        },
      }),
    );
  };

  return (
    <>
      <div className="p-6">
        <div className="pt-10 pb-2 text-sm font-normal text-gray-900">
          <FormattedMessage {...messages.current_pass} />
        </div>
        <div className="relative flex items-center">
          <input
            type={currentType}
            onChange={e => setCurrentPassword((e.target.value || '').trim())}
            value={currentPassword}
            className="w-[470px] border border-gray-200 rounded py-1.5 px-2 font-normal text-sm h-10  text-gray-900"
            data-testid="current-password"
            disabled={loading}
          />
          <FontAwesomeIcon
            icon={currentType === 'password' ? faEye : faEyeSlash}
            className="absolute left-[436px]"
            onClick={() => handleNewPasswordEyeClick(currentType, setCurrentType)}
          />
        </div>

        <div className="pt-6 pb-2 text-sm font-normal text-gray-900">
          <FormattedMessage {...messages.new_pass} />
        </div>

        <div className="relative flex items-center">
          <input
            type={newType}
            onChange={e => {
              setNewPassword((e.target.value || '').trim());
            }}
            value={newPassword}
            className={classNames(
              'w-[470px] border  rounded py-1.5 px-2 font-normal text-sm h-10  text-gray-900',
              samePasswordError || newPasswordError?.errors ? 'border-rose-600' : 'border-gray-200',
            )}
            data-testid="new-password"
            disabled={loading}
          />
          <FontAwesomeIcon
            icon={newType === 'password' ? faEye : faEyeSlash}
            className="absolute left-[436px]"
            onClick={() => handleNewPasswordEyeClick(newType, setNewType)}
            data-testid="password-eye"
          />
        </div>
        {/* errors */}
        {newPasswordError?.errors && <ChangePasswordErrors error={newPasswordError?.errors} />}

        <div className="pt-6 pb-2 text-sm font-normal text-gray-900">
          <FormattedMessage {...messages?.retype_new_pass} />
        </div>
        <div className="relative flex items-center">
          <input
            type={reType}
            value={retypePassword}
            className={classNames(
              'w-[470px] border  rounded py-1.5 px-2 font-normal text-sm h-10  text-gray-900',
              samePasswordError ? 'border-rose-600' : 'border-gray-200',
            )}
            onChange={e => {
              setRetypePassword((e.target.value || '').trim());
            }}
            data-testid="retype-password"
            disabled={loading}
          />
          <FontAwesomeIcon
            icon={reType === 'password' ? faEye : faEyeSlash}
            className="absolute left-[436px]"
            onClick={() => handleNewPasswordEyeClick(reType, setReType)}
          />
        </div>

        {/* same password validation error */}
        <div className="text-rose-600 font-normal text-xs pt-2.5">
          {samePasswordError && (
            <li>
              <FormattedMessage {...messages?.pass_dont_match} />
            </li>
          )}
        </div>

        <div className="flex justify-start w-full pt-10 pr-2 ">
          <Button
            size="lg"
            onClick={handleSubmit}
            disabled={
              newPasswordError?.errors ||
              samePasswordError ||
              !currentPassword ||
              !retypePassword ||
              !newPassword ||
              loading
            }
          >
            <FormattedMessage {...messages?.change_password} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
