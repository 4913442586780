/**
 *
 * NotificationsPopoverContent
 *
 */

import { faMessageSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/base/Avatar';
import { DateTime } from 'luxon';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export function NotificationsPopoverContent(props) {
  const { notifications } = props;

  const formattedDuration = useCallback(
    time => {
      const duration = DateTime.fromISO(time).toRelative();
      if (duration) {
        return duration;
      }
      return null;
    },
    [notifications],
  );

  return (
    <div className="w-96 h-[444px] rounded border border-gray-200 overflow-hidden">
      <div className="w-full flex justify-between items-center px-6 pt-5 pb-2.5 border-b border-gray-200">
        <span className="text-sm font-semibold text-gray-900">
          <FormattedMessage {...messages.header} />
        </span>
        <span className="text-xs text-zinc-600 cursor-pointer">
          <FormattedMessage {...messages.mark_read} />
        </span>
      </div>
      <div className="px-5 py-1 h-[400px] overflow-auto">
        {notifications && notifications?.length > 0 ? (
          notifications?.map(notification => (
            <div
              className="flex gap-x-3 py-3 border-b border-gray-200 last:border-white cursor-pointer"
              key={notification.id}
            >
              <Avatar data={notification.user} />
              <div className="text-xs font-medium text-gray-900 mb-1">{notification.msg}</div>
              <div className="text-[10px] leading-[19px] text-gray-500">{formattedDuration(notification.time)}</div>
            </div>
          ))
        ) : (
          <div className="flex flex-col justify-center items-center w-full h-full text-gray-400">
            <FontAwesomeIcon icon={faMessageSlash} className="text-[68px]" />
            <div className="text-xs mt-5">
              <FormattedMessage {...messages.no_messages} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

NotificationsPopoverContent.propTypes = {};

export default memo(NotificationsPopoverContent);
