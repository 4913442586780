/**
 *
 * AvatarGroup
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { map, slice, get } from 'lodash';

import classNames from 'classnames';
import Avatar from '../Avatar';

export function AvatarGroup(props) {
  const { users, limit } = props;
  const zMap = ['z-10', 'z-20', 'z-30', 'z-40', 'z-50'];
  return (
    <div className="flex -space-x-2 relative z-0 overflow-hidden">
      {map(slice(users, 0, limit), (user, index) => (
        <div
          className={classNames('inline-block rounded-full ring-2 ring-white', get(zMap, index))}
          key={get(user, 'id')}
        >
          <Avatar showName={false} user={user} showTooltip />
        </div>
      ))}
    </div>
  );
}

AvatarGroup.propTypes = {
  users: PropTypes.array,
  limit: PropTypes.oneOf([...new Array(5)].map((_, i) => i + 1)),
};

Avatar.defaultProps = {
  limit: 5,
};

export default memo(AvatarGroup);
