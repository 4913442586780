/**
 *
 * Navbar
 *
 */

import React, { memo, useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { debounce, trim } from 'lodash';
import PropTypes from 'prop-types';
import Nav from './Nav';
import getPath from './path';
import getItems, { getRole } from './items';

export function Navbar(props) {
  const { hidden, path, user, globalSearch } = props;
  const [query, setQuery] = useState(null);
  const { pathname, search } = useLocation();
  const role = localStorage.getItem('tv.ae') ? JSON.parse(localStorage.getItem('tv.ae')).role : ['Content Manager'];
  const qp = new URLSearchParams(search);
  const key = qp.get('search');
  const history = useHistory();
  const { displayName, accountName, userName, organization, isLoggedIn } = user;
  const { name, disabled, isRealTime } = getPath({ pathname, path });

  const searchFn = q => {
    if (q) {
      history.push(`${pathname}?search=${encodeURIComponent(trim(q))}`);
    } else {
      history.push(`${pathname}`);
    }
  };
  const fn = useCallback(
    debounce(q => searchFn(q), 500),
    [],
  );
  useEffect(() => {
    if (isRealTime) {
      fn(query);
    }
  }, [query]);

  const items = getItems(getRole(role));

  const onKeyDown = event => {
    if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape') {
      searchFn(query);
    }
  };

  if (hidden) return <></>;
  return (
    <>
      {isLoggedIn && (
        <Nav
          onKeyDown={onKeyDown}
          items={items}
          name={name}
          displayName={displayName || ''}
          userName={userName}
          pathname={pathname}
          setQuery={setQuery}
          accoutName={`#${organization} - ${accountName}`}
          searchKey={key}
          disabled={disabled}
          globalSearch={globalSearch}
        />
      )}
    </>
  );
}

Navbar.propTypes = {
  path: PropTypes.string,
  hidden: PropTypes.bool,
  user: PropTypes.object,
  globalSearch: PropTypes.bool,
};

Navbar.defaultProps = {
  hidden: false,
  user: {},
  globalSearch: false,
};

export default compose(memo)(Navbar);
