/**
 *
 * PhoneNumberUpdate
 *
 */

import React, { memo, useState, useEffect } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import 'react-phone-number-input/style.css';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { updateUser } from 'containers/Action/actions';
import actionSaga from 'containers/Action/saga';
import actionReducer from 'containers/Action/reducer';
import { fetchUserData } from 'containers/App/actions';
export function PhoneNumberUpdate(props) {
  useInjectReducer({ key: 'action', reducer: actionReducer });
  useInjectSaga({ key: 'action', saga: actionSaga });
  const dispatch = useDispatch();
  const { value = '', toggleEditPhoneNumber, isLoading, setIsLoading, data } = props;
  const [phoneNumber, setPhoneNumber] = useState(value);
  const [hasError, hasSetError] = useState(true);

  useEffect(() => {
    if (phoneNumber) {
      hasSetError(() => !isValidPhoneNumber(phoneNumber));
    }
  }, [phoneNumber]);

  const handlePhoneUpdate = () => {
    setIsLoading(true);
    if (!hasError) {
      const payload = {
        phone_number: phoneNumber,
      };
      dispatch(
        updateUser({
          payload,
          callback: {
            onSuccess: () => {
              dispatch(fetchUserData());
              toggleEditPhoneNumber(false);
              setIsLoading(false);
            },
            onError: () => {
              setIsLoading(false);
            },
          },
        }),
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center ">
        <div data-testid="phone-input">
          <PhoneInput
            disabled={isLoading}
            international
            value={data?.phone_number}
            onChange={setPhoneNumber}
            defaultCountry="IN"
          />
        </div>
        {isLoading ? (
          <>
            <div className="w-4 h-2 ml-1" data-chromatic="ignore" data-testid="loadingState">
              <span>Updating</span>
            </div>
          </>
        ) : (
          <>
            <div className="w-4 ml-3 cursor-pointer text-lime-700">
              <FontAwesomeIcon icon={faCheck} onClick={handlePhoneUpdate} data-testid="goAheadIcon" />
            </div>
            <div className="w-4 ml-3 cursor-pointer text-rose-600">
              <FontAwesomeIcon icon={faXmark} onClick={() => toggleEditPhoneNumber(false)} data-testid="goBackIcon" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

PhoneNumberUpdate.propTypes = {};

export default memo(PhoneNumberUpdate);
