/**
 *
 * ProfilePopoverContent
 *
 */

import { faArrowRightFromBracket, faGear } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { handleEnterKeyPress } from 'utils/helpers';
import { formatRoles } from 'utils/auth';
import Apollo from 'utils/apollo';
import messages from './messages';

const version = process.env.VERSION || 'testing';

export const ProfilePopoverContent = ({ name, user_role, showAccountSettings, accountSettings }) => {
  const history = useHistory();

  const handleLogout = async () => {
    localStorage.removeItem('tv.ae');
    await Apollo.clearCache();
    await Apollo.removeClient();
    history.push('/logout');
  };

  return (
    <div className="border border-gray-200 pb-3 px-4 w-52 rounded-md shadow-[0px_4px_24px_rgba(17,24,39,0.08)]">
      <div className="pt-4 pb-3 ">
        <div className="mb-1 text-sm font-semibold text-gray-900">{name}</div>
        <div className="text-sm text-gray-600">{formatRoles(user_role)}</div>
      </div>
      <div
        className={classNames(
          'flex items-center py-2.5 cursor-pointer hover:bg-gray-100 px-2',
          accountSettings ? 'text-blue-700 bg-blue-50' : 'text-gray-700',
        )}
        onClick={showAccountSettings}
        onKeyDown={e => handleEnterKeyPress(e, showAccountSettings)}
        role="presentation"
      >
        <FontAwesomeIcon icon={faGear} className="text-lg" />
        <span className="ml-2 text-sm">
          <FormattedMessage {...messages.account_settings} />
        </span>
      </div>
      <div
        className={classNames('flex items-center text-gray-700  py-2.5 cursor-pointer hover:bg-gray-100 px-2')}
        onClick={handleLogout}
        onKeyDown={handleLogout}
        role="presentation"
      >
        <FontAwesomeIcon icon={faArrowRightFromBracket} className="text-lg" />
        <span className="ml-2 text-sm">
          <FormattedMessage {...messages.signOut} />
        </span>
      </div>
      <div className="mt-5 text-sm text-gray-500">
        <FormattedMessage {...messages.version} /> {version}
      </div>
    </div>
  );
};

ProfilePopoverContent.propTypes = {};

export default memo(ProfilePopoverContent);
