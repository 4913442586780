import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const ChangePasswordErrors = ({ error }) => (
  <div className="font-normal text-xs pt-2.5">
    <li className={classNames(error?.length ? 'text-rose-600' : 'text-gray-600')}>
      <FormattedMessage {...messages?.eight_character} />
      {!error?.length ? <FontAwesomeIcon icon={faCheck} className="text-lime-700 ml-2 h-4 w-4" /> : ''}
    </li>
    <li className={classNames(error?.lowercase || error?.uppercase ? 'text-rose-600' : 'text-gray-600')}>
      <FormattedMessage {...messages?.uppercase_letters} />
      {!error?.lowercase && !error?.uppercase ? (
        <FontAwesomeIcon icon={faCheck} className="text-lime-700 ml-2 h-4 w-4" />
      ) : (
        ''
      )}
    </li>
    <li className={classNames(error?.specialChar ? 'text-rose-600' : 'text-gray-600')}>
      <FormattedMessage {...messages?.special_characters} />
      {!error?.specialChar ? <FontAwesomeIcon icon={faCheck} className="text-lime-700 h-4 w-4" /> : ''}
    </li>
  </div>
);

export default ChangePasswordErrors;
