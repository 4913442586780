/**
 *
 * Select
 *
 */

import React, { useState, useEffect, Fragment, memo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Badges from 'components/base/Badges';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XIcon, ExclamationIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import badgesmap from 'containers/QuestionListView/Questions/utils/statusmap';
import { filter, uniqBy, find } from 'lodash';

export const Select = forwardRef((props, ref) => {
  const {
    value,
    label,
    options,
    onChange,
    isMulti,
    disabled,
    searchFn,
    nullable,
    emptyLabel,
    error,
    placeholder,
    dropitems,
    errorMessage,
    clearSelection,
    isSearchable,
  } = props;
  const [selectedItems, setSelected] = useState(value || (isMulti ? dropitems : dropitems));
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleChange = val => {
    const items = isMulti ? uniqBy(val, 'label') : val;
    if (onChange) onChange(items);
    setSelected(val);
  };

  useImperativeHandle(ref, () => ({
    updateValue(val) {
      handleChange(val);
    },
  }));

  useEffect(() => {
    if (searchFn) {
      setLoading(true);
      searchFn(query).then(r => {
        setFilteredOptions(r);
        setLoading(false);
      });
    } else {
      setFilteredOptions(options, query, { keys: ['searchKey', 'label', 'value'] });
    }
  }, [query, dropitems, options]);

  useEffect(() => {
    if (clearSelection) {
      const emptyItem = isMulti ? [] : {};
      handleChange(emptyItem);
    }
  }, [clearSelection]);

  return (
    <Combobox
      as="div"
      value={selectedItems}
      onChange={handleChange}
      multiple={isMulti}
      nullable={nullable}
      disabled={disabled}
    >
      {label && (
        <Combobox.Label className="block text-sm font-medium text-gray-700" as="label">
          {label}
        </Combobox.Label>
      )}
      {isMulti && selectedItems && selectedItems?.length > 0 && (
        <ul
          className={classNames(
            'mb-1 space-x-1 ',
            'flex',
            'w-full p-2 overflow-x-scroll overflow-y-scroll hover:overflow-x-auto',
          )}
        >
          {uniqBy(selectedItems, 'label')?.map((item, index) => (
            <li key={`${item.id}-${index}` || item.label} className="inline ">
              <span
                className={classNames(
                  'inline-flex items-center px-2.5 py-0.5 rounded-full ',
                  'text-xs font-medium bg-sky-50 text-sky-600 justify-between items-center whitespace-nowrap',
                )}
              >
                {item.label}
                <XIcon
                  className="w-3 h-3 ml-1 -mr-1 cursor-pointer text-sky-600"
                  onClick={() => {
                    const filteredItems = filter(selectedItems, i => i.label !== item.label);
                    handleChange(filteredItems);
                  }}
                  data-testid={`${item.label}-multiclose`}
                />
              </span>
            </li>
          ))}
        </ul>
      )}

      <div className="relative mt-1">
        <Combobox.Input
          className={classNames(
            disabled ? 'bg-zinc-100' : 'bg-white',
            'w-full rounded-md border border-gray-300  ',
            'py-2 pl-3 pr-10 shadow-sm focus:border-sky-600 focus:outline-none focus:ring-1',
            'focus:ring-sky-600 sm:text-sm',
            error ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300' : '',
          )}
          onChange={event => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          placeholder={placeholder || 'Select'}
          readOnly={!isSearchable} // Set readOnly based on the 'searchable' prop
          displayValue={option =>
            // eslint-disable-next-line no-nested-ternary
            option ? (typeof option.label === 'string' ? option.label : option.selectLabel) : ''
          }
        />

        <Combobox.Button
          className={classNames('absolute inset-y-0 right-0', 'flex items-center rounded-r-md px-2 focus:outline-none')}
        >
          {loading ? (
            <svg
              className={classNames('animate-spin -ml-1 mr-1 text-gray-400 h-5 w-5')}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="5" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
             7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            <ChevronDownIcon data-testid="selector" className="w-5 h-5 text-gray-400" aria-hidden="true" />
          )}
        </Combobox.Button>

        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Combobox.Options
            className={classNames(
              'absolute z-40 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1',
              'text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
            )}
          >
            {filteredOptions?.length > 0 ? (
              <>
                {filteredOptions.map(option => (
                  <Combobox.Option
                    key={option.id || option.value}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                        active ? 'bg-zinc-100 text-gray-900' : 'text-gray-900',
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex items-center">
                          {option.imageUrl && (
                            <img src={option.imageUrl} alt="" className="flex-shrink-0 w-6 h-6 rounded-full" />
                          )}
                          <span
                            className={classNames(
                              option.imageUrl ? 'ml-3' : '',
                              'block truncate',
                              selected && find(selectedItems, { value: option.value }) && 'font-medium',
                            )}
                          >
                            {option.hasBadge ? (
                              <Badges
                                text={option.label}
                                backgroundColor={badgesmap(option.label)}
                                variant="rounded"
                                size="sm"
                              />
                            ) : (
                              <>{option.label}</>
                            )}
                          </span>
                        </div>

                        {selected && find(selectedItems, { value: option.value }) && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-sky-600' : 'text-sky-600',
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            ) : (
              <Combobox.Option
                disabled
                className={classNames('relative cursor-default select-none py-2 pl-3 pr-9 ', 'text-gray-500')}
              >
                {emptyLabel}
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Transition>
      </div>
      {error && <p className="mt-1 ml-1 text-sm text-red-600">{error}</p>}
      {errorMessage && (
        <div className="flex px-4 pt-4 mt-3 bg-yellow-50">
          <span className="flex mr-2">
            <ExclamationIcon className="w-5 h-5 text-yellow-400" />
          </span>
          <span>
            <p className="text-sm font-semibold text-yellow-800">Warning</p>
            <p className="py-3 text-sm font-normal text-yellow-700">{errorMessage}</p>
          </span>
        </div>
      )}
    </Combobox>
  );
});

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      searchKey: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      imageUrl: PropTypes.string,
    }),
  ),
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      }),
    ),
    PropTypes.string,
    PropTypes.number,
    PropTypes.oneOfType([PropTypes.element, PropTypes.string]), // ?
  ]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  nullable: PropTypes.bool,
  searchFn: PropTypes.func,
  emptyLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  dropitems: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  clearSelection: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

Select.defaultProps = {
  options: [],
  disabled: false,
  isMulti: false,
  nullable: true,
  emptyLabel: 'No Options',
  dropitems: [],
  clearSelection: false,
  isSearchable: false,
};

export default memo(Select);
