import { useState, useEffect } from 'react';
import fetchData from 'utils/fetchData';
import { FETCH_FILE_URL_QUERY } from '../containers/Action/queries';

const useFetchFileUrl = profile_pic_id => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    if (profile_pic_id) {
      const queryVariables = { id: profile_pic_id };
      fetchData({
        queryString: FETCH_FILE_URL_QUERY,
        queryVariables,
        queryKey: 'file_download_file',
      }).then(res => {
        setUrl(res?.resource_url);
      });
    }
  }, [profile_pic_id]);

  return { url };
};

export default useFetchFileUrl;
