/* eslint-disable no-console, import/no-import-module-exports */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { ConnectedRouter } from 'connected-react-router';
import { Workbox /* messageSW */ } from 'workbox-window';

import history from 'utils/history';
import { loadState } from 'utils/state';
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import { version } from './version';

// Import Language Provider

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';

/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

Sentry.init({
  dsn: 'https://e52292ac03ad473b9e3d1a03452ccf39@o286634.ingest.sentry.io/4505284374560768',
  integrations: [new Integrations.BrowserTracing()],
  release: version || 'dev',
  environment: process.env.NODE_ENV || 'dev',
  tracesSampleRate: process.env.NODE_ENV !== 'production' ? 1.0 : 0.5,
  beforeSend: (event, hint) => {
    if (window.location.hostname === 'localhost') {
      console.error('Error logged by sentry: ', hint.originalException || hint.syntheticException);
      return null; // this drops the event and nothing will be sent to sentry
    }
    return event;
  },
});

// Create redux store with history
const initialState = { saved: loadState() };

const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// eslint-disable-next-line no-console
ReactGA.initialize('UA-194793975-2');
const root = createRoot(MOUNT_NODE);
const render = messages => {
  root.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
  );
};

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     // root.unmount();
//     render(translationMessages);
//   });
// }

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  // eslint-disable-next-line no-promise-executor-return
  new Promise(resolve => resolve(import('intl')))
    // eslint-disable-next-line import/extensions
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js');
  // eslint-disable-next-line no-unused-vars
  let registration;
  const showSkipWaitingPrompt = event => {
    if (event.isUpdate) {
      // Notification({
      //   title: 'Update available!',
      //   message: 'A new version is available, click to update',
      //   duration: 0,
      //   onClick: async () => {
      //     if (registration && registration.waiting) {
      //       await messageSW(registration.waiting, { type: 'SKIP_WAITING' });
      //     }
      //     window.location.reload();
      //   },
      // });
    }
  };
  wb.addEventListener('waiting', showSkipWaitingPrompt);
  wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

  wb.addEventListener('installed', showSkipWaitingPrompt);

  // eslint-disable-next-line no-return-assign
  wb.register().then(r => (registration = r));
}
