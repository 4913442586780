import { gql } from '@apollo/client';

export const INSERT_VIDEO_QUESTION = gql`
  mutation InsertVideoQuestion(
    $content: jsonb!
    $level: qbm_question_level_enum
    $question_bank_id: Int!
    $type: qbm_question_type_enum
    $question_skills: qbm_question_skill_arr_rel_insert_input
    $status: String!
    $preparation_time: interval!
    $answer_time: interval!
    $blooms_taxonomy: qbm_question_blooms_taxonomy_arr_rel_insert_input
    $external_id: String
  ) {
    insert_qbm_question(
      objects: {
        external_id: $external_id
        content: $content
        level: $level
        type: $type
        question_bank_id: $question_bank_id
        question_skills: $question_skills
        status: $status
        preparation_time: $preparation_time
        answer_time: $answer_time
        question_blooms_taxonomies: $blooms_taxonomy
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export default INSERT_VIDEO_QUESTION;
