import {
  isArray,
  map,
  toNumber,
  isEmpty,
  trim,
  split,
  compact,
  flatten,
  filter,
  replace,
  get,
  find,
  pickBy,
  identity,
  reduce,
  has,
  isObject,
  isNil,
  range,
  includes,
} from 'lodash';

import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { ROLE_EVALUATOR, ROLE_MASTER_RECRUITER, getCurrentRole } from './roles';

export function isNumeric(str) {
  if (typeof str !== 'string') return false; // we only process strings!
  // eslint-disable-next-line no-restricted-globals
  return !Number.isNaN(str) && !isNaN(str);
}

export function formatBytes(bytes, decimals = 0) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function formattedTime(value) {
  try {
    return value ? DateTime.fromSeconds(value).toISO().substr(11, 8) : null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export async function getDataByIds(value, getByIds) {
  const v = !isArray(value) ? [trim(value)] : value;
  const ids = map(v, i => toNumber(i));

  if (ids.length === 0 || isEmpty(value)) {
    return Promise.resolve([]);
  }

  return getByIds({ ids });
}

export const makeArray = attr => {
  let res;
  if (attr) {
    res = !isArray(attr) ? `[${split(attr, ',').toString()}]` : `[${attr.toString()}]`;
  }
  return res;
};

export const getParams = (filters, f) =>
  reduce(
    f,
    (o, value, key) =>
      find(filters, { key })
        ? { ...o, ...{ [key]: get(find(filters, { key }), 'isDate', false) ? split(value, ',') : value } }
        : o,
    {},
  );

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export const historyUpdate = ({ filterKeys, searchString, currentPage, base, history, filters, loading }) => {
  let fc;
  if (filterKeys) {
    fc = reduce(
      filterKeys,
      (a, i) => (has(filters, i) && !isNil(filters[i]) ? `${a ? `${a}&` : ''}${i}=${filters[i]}` : a),
      '',
    );
  } else {
    fc = reduce(Object.keys(filters), (a, i) => (!isNil(filters[i]) ? `${a}&${i}=${filters[i]}` : a), '');
  }

  const qc = searchString ? `search=${encodeURIComponent(searchString)}` : '';
  const pc = currentPage >= 1 ? `&page=${currentPage}` : '';
  const newRoute = `/${base}?${qc}${fc}${pc}`;

  if (!loading && `?${qc}${fc}${pc}` !== history.location.search) {
    history.push(newRoute);
  }
};

export function pageNumbers({ pages, current }) {
  let max = pages;
  if (pages > current + 2 && current <= 3) {
    max = 5;
  } else if (current + 2 < pages) {
    max = current + 2;
  }
  let min = 1;
  if (pages < current + 3 && current > 3) {
    min = pages - 4;
  } else if (current > 3) {
    min = current - 2;
  }
  return range(min, max + 1);
}
export const getQueryString = filteredParams =>
  reduce(
    Object.keys(filteredParams),
    (a, i) =>
      has(filteredParams, i) && !isNil(filteredParams[i]) && !isObject(filteredParams[i]) && !isEmpty(filteredParams[i])
        ? `${a ? `${a}&` : ''}${i}=${encodeURIComponent(filteredParams[i])}`
        : a,
    '',
  );

export const parseJson = items =>
  compact(
    map(items, item => {
      try {
        return JSON.parse(item);
      } catch (e) {
        Sentry.captureException(e);
        return null;
      }
    }),
  );

export const parseConditions = ({ fields, variables }) => {
  const items = flatten(
    map(
      filter(fields, f => f.query),
      'query',
    ),
  );

  return parseJson(
    map(pickBy(variables, identity), (value, key) =>
      replace(get(find(items, { key: `$${key}` }), 'condition'), new RegExp(`[$]${key}`, 'g'), value),
    ),
  );
};

export const removeHtmlTagsAndEntities = text => {
  const cleanText = text
    .replace(/<.*?>/g, '')
    .replace(/&nbsp;/g, ' ')
    .trim();
  return cleanText;
};

export const questionTypeMap = value => {
  let result = '';
  switch (value) {
    case 'multiple_choice':
      result = 'MCQ';
      break;
    case 'multiple_choice_answer':
      result = 'MCA';
      break;
    case 'essay':
      result = 'Essay';
      break;
    case 'live':
      result = 'Live Interview';
      break;
    case 'video':
      result = 'Video Interview';
      break;
    case 'coding':
      result = 'Coding';
      break;
    case 'code_pair':
      result = 'Code Pair';
      break;
    default:
      result = '';
  }
  return result;
};

export const particularKeysSum = (arr = [], key) => {
  const sum = arr?.length && arr?.reduce((acc, obj) => acc + obj[key], 0);
  return sum;
};

export function formatTime(minutes) {
  let newFormattedTime = '';
  if (minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes - hours * 60;
    // const seconds = 0;
    if (hours < 10) {
      newFormattedTime += `0${hours}:`;
    } else {
      newFormattedTime += `${hours}:`;
    }

    if (remainingMinutes < 10) {
      newFormattedTime += `0${remainingMinutes}:`;
    } else {
      newFormattedTime += `${remainingMinutes}:`;
    }

    // Add the seconds segment if needed
    newFormattedTime += '00';
  }

  return newFormattedTime;
}
export function formatQuestionType(type) {
  if (type === 'multiple_choice') {
    return 'Objective';
  }
  return type.replace(/_/g, ' ');
}

export const parseSectionType = value => {
  if (value === 'INTERVIEW_ROOMS') {
    return 'Interview Rooms';
  }
  if (value === 'CODING') {
    return 'Coding';
  }
  if (value === 'EXTERNAL') {
    return 'External';
  }
  return 'Objective';
};

export const convertToUpperCase = value => value.toUpperCase().replaceAll(' ', '_');

export const processCreatedByData = data => {
  const emailMap = data.reduce((dataMap, obj) => {
    const { email, id, name, label } = obj;
    if (email) {
      if (!dataMap.has(email)) {
        dataMap.set(email, { id: new Set(), name, label });
      }
      dataMap.get(email).id.add(id);
    }
    return dataMap;
  }, new Map());

  const result = Array.from(emailMap, ([email, { id, name, label }]) => {
    // eslint-disable-next-line no-param-reassign
    id = Array.from(id);
    if (id.length === 1) {
      return { email, id: id[0], name, label };
    }
    return { email, id, name, label };
  });

  return result;
};

export function isSingleObject(obj) {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    return false; // Not an object or is an array
  }
  return true;
}

export function convertMinutesToHours(minutes) {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hours ${remainingMinutes} minutes`;
  }
  return `${minutes} minutes`;
}

export function convertTimeToMinutes(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return Math.floor(totalMinutes);
}

export const formatData = (data, badge = false) => {
  if (data && data.length) {
    return map(data, option => ({
      ...option,
      label: option?.value || option?.name,
      value: option?.id || option?.value || option?.name,
      type: 'Primary',
      hasBadge: badge,
    }));
  }
  return data;
};

export const userRoleData = fetchUsersData =>
  fetchUsersData.filter(item =>
    item?.user_roles?.some(roleObj => roleObj.role === 'CONTENT_MANAGER' || roleObj.role === 'SUBJECT_MATTER_EXPERT'),
  );

export const userSmeRoleData = fetchSmeUsers =>
  fetchSmeUsers.filter(item => item?.user_roles?.some(roleObj => roleObj.role === 'SUBJECT_MATTER_EXPERT'));

export const handleEnterKeyPress = (event, callback) => {
  if (event.keyCode === 13) {
    callback(event);
  }
};

export function formatTimeAgo(sendTime) {
  const sendDate = DateTime.fromISO(sendTime);
  const now = DateTime.now();

  return sendDate.toRelative(now);
}

export function calculateColor(value1, value2) {
  const percentage = (value1 / value2) * 100;

  if (percentage <= 0) {
    return '#E2E8F0';
  }
  if (percentage <= 39) {
    return '#DC2626';
  }
  if (percentage <= 69) {
    return '#FBBF24';
  }
  if (percentage <= 100) {
    return '#16A34A';
  }

  return '#000000';
}

export function toTitleCase(str) {
  // Check if str is a string, otherwise return an empty string
  if (typeof str !== 'string') {
    return '';
  }

  return str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());
}
export const getHasuraHeaders = graphToken => get(parseJwt(graphToken), 'https://hasura.io/jwt/claims');

export const getHasuraHeaderByName = (graphToken, name) => {
  const parsedHeaders = getHasuraHeaders(graphToken);
  return get(parsedHeaders, name) || get(parsedHeaders, (name || '').toLowerCase());
};
export const getRequiredPathRoleAccess = () => {
  const path = window.location.href;
  const isPanelRoute = path.includes('/panel/');
  if (isPanelRoute) {
    return ROLE_EVALUATOR;
  }
  return ROLE_MASTER_RECRUITER;
};

export const validateRoleAccess = (roles, requiredRole = getRequiredPathRoleAccess()) => includes(roles, requiredRole);

export const getDefaultContext = graphToken => {
  const roles = getHasuraHeaderByName(graphToken, 'X-Hasura-Allowed-Roles');
  const requiredRole = getRequiredPathRoleAccess();
  const haveAccess = validateRoleAccess(roles, requiredRole);
  return {
    headers: {
      'x-hasura-role': haveAccess ? requiredRole : getCurrentRole(roles),
    },
  };
};
