import { gql } from '@apollo/client';

export const GET_USER_PROFILE_DETAILS = gql`
  query getCurrentUser {
    auth_user_me {
      id
      name
      username
      email
      old_id
      tenant {
        name
        id
        subdomain
      }
      phone_number
      external_id
      profile_pic_file_id
      # timezone
    }
  }
`;
export const GET_TIMEZONES = gql`
  query getTimezones {
    md_timezone(order_by: { value: asc }) {
      description
      value
    }
  }
`;
