import React, { Fragment, memo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Disclosure, Menu } from '@headlessui/react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { SearchIcon } from '@heroicons/react/solid';
import { CogIcon, LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookFeatureFlags } from 'utils/featureFlags';

import classNames from 'classnames';

import Logo from 'images/talview_white.png';
import LogoIcon from 'images/icon-color.png';

import Dropdown from '../Dropdown';
import Photo from '../Photo';

import messages from './messages';

export function Nav(props) {
  const {
    items,
    pathname,
    setQuery,
    onKeyDown,
    searchKey,
    disabled,
    name,
    displayName,
    accountName,
    userName,
    globalSearch,
  } = props;
  const history = useHistory();
  const searchRef = useRef();
  const handleClick = index => {
    if (!index.includes('http')) {
      if (globalSearch) {
        searchRef.current.value = '';
      }
      history.push(index || '/question-bank');
    } else if (index !== '#') {
      window.location.href = index;
    }
  };
  const ProfileDropDown = useCallback(
    active => (
      <div className={classNames('ml-3 space-y-1', active ? 'bg-gray-50' : '')}>
        <div className="text-sm font-medium text-gray-800 truncate">{displayName}</div>
        <div className="text-sm font-medium text-gray-500 truncate">{userName}</div>
        <div className="text-sm font-medium text-gray-500 truncate">{accountName}</div>
      </div>
    ),
    [displayName, userName, accountName],
  );

  const reviewWorkflowEnabled = useFeatureIsOn(growthBookFeatureFlags.REVIEW_WORK_FLOW_ENABLE);

  return (
    <Disclosure as="nav" className="bg-[#152029] shadow z-50">
      {({ open }) => (
        <>
          <div className="max-w-full px-2 mx-auto sm:px-8 lg:px-12">
            <div className="flex items-center justify-between h-16 align-middle">
              <div className="flex px-2 lg:px-0">
                <div className="flex items-center flex-shrink-0 pr-12">
                  <img className="block w-auto lg:hidden h-7" src={LogoIcon} alt="Talview" />
                  <img className="hidden w-auto lg:block h-7" src={Logo} alt="Talview" />
                </div>
                <div className="items-center justify-center hidden lg:ml-6 lg:flex lg:space-x-8">
                  {items
                    .filter(
                      item =>
                        !(!reviewWorkflowEnabled && item?.label?.defaultMessage === ('SME' || 'SmeReviewQuestion')),
                    )
                    .map(item => (
                      <div
                        role="button"
                        aria-hidden
                        onClick={() => handleClick(get(item, 'index'))}
                        key={get(item, 'index')}
                        className={`${
                          get(item, 'index') === pathname ? 'text-white h-16' : 'text-stone-300 hover:text-white'
                        }  hover:text-white py-1 rounded-sm text-sm font-normal items-center justify-center flex`}
                      >
                        <span className="p-1">{item.icon}</span>
                        <FormattedMessage {...get(item, 'label')} />
                        {item.isDropdown && <Dropdown items={[]} />}
                      </div>
                    ))}
                  {/* Current: "border-indigo-500 text-gray-900",
                  Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                </div>
              </div>
              {globalSearch && (
                <div className="flex items-center justify-center flex-1 px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      <FormattedMessage {...messages.search} />
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        id="search"
                        name="search"
                        onChange={e => setQuery(e.target.value)}
                        ref={searchRef}
                        onKeyDown={onKeyDown}
                        defaultValue={searchKey}
                        disabled={disabled}
                        placeholder={`Search ${name || ''}`}
                        className="block w-full py-1 pl-10 pr-3 leading-5 placeholder-gray-500
                        bg-white border border-gray-300 rounded-md focus:outline-none focus:placeholder-gray-400
                         focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                         "
                        type="search"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="inline-flex items-center justify-center
                p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500
                "
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                {/* Profile dropdown */}
                <Dropdown
                  customControl={
                    <Menu.Button
                      className={classNames(
                        'rounded-full flex text-sm focus:outline-none',
                        'w-fit focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      )}
                    >
                      <span className="sr-only">Open menu</span>
                      <Photo name={displayName} id={get(displayName, 'length', 5)} />
                    </Menu.Button>
                  }
                  items={[
                    {
                      id: 1,
                      group: [
                        {
                          id: 11,
                          Content: ProfileDropDown,
                        },
                      ],
                    },
                    {
                      id: 2,
                      group: [
                        {
                          id: 3,
                          Icon: CogIcon,
                          label: <FormattedMessage {...messages.settings} />,
                          onClick: () => {},
                        },
                        {
                          id: 4,
                          Icon: LogoutIcon,
                          label: <FormattedMessage {...messages.logout} />,
                          onClick: () => history.push('/logout'),
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700",
              Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
              {items.map(item => (
                <Disclosure.Button
                  as="a"
                  key={get(item, 'index')}
                  href={get(item, 'index')}
                  className={`${
                    get(item, 'index') === pathname ? 'text-white' : 'hover:text-white bg-[#152029] text-stone-300'
                  }
                  'block pl-3 pr-4 py-1 border-l-4 text-base font-light border-transparent flex`}
                >
                  <span className="p-1">{item?.icon} </span>
                  <FormattedMessage {...get(item, 'label')} />
                </Disclosure.Button>
              ))}
            </div>
            <div className="pt-3 pb-2 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {/* <div className="w-10 h-10 rounded-full"> */}
                  <Photo name={displayName} id={get(displayName, 'length', 5)} data-testid="display-name" />
                  {/* </div> */}
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-gray-500 truncate">{displayName}</div>
                  <p className="text-xs font-medium text-gray-500 truncate ">{userName}</p>
                  <div className="text-xs font-medium text-gray-500 truncate">{accountName}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-1 text-base font-medium text-gray-500
                  hover:text-gray-800 hover:bg-gray-100
                   "
                >
                  <FormattedMessage {...messages.settings} />
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/logout"
                  className="block px-4 py-1 text-base font-medium text-gray-500
                  hover:text-gray-800 hover:bg-gray-100
                  "
                >
                  <FormattedMessage {...messages.logout} />
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

Nav.propTypes = {
  setQuery: PropTypes.func,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  pathname: PropTypes.string,
  searchKey: PropTypes.string,
  userName: PropTypes.string,
  accountName: PropTypes.string,
  name: PropTypes.string,
  displayName: PropTypes.string,
  globalSearch: PropTypes.bool,
};

export default memo(Nav);
