/**
 *
 * NotFound
 *
 */

import React, { memo } from 'react';
import { HomeIcon } from '@heroicons/react/solid';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Navbar, Button } from 'components/base';
import Img from 'images/404.svg';
import messages from './messages';

export function NotFound() {
  const history = useHistory();
  return (
    <div className="h-screen bg-slate-50 relative">
      <div className="fixed top-0 left-0 right-0">
        <Navbar />
      </div>
      <div className="h-full flex justify-center items-center ">
        <div className="md: flex shadow-lg  items-center p-6 rounded-md bg-white">
          <div className="flex flex-col space-y-8 flex-shrink-0 md:w-2/5 w-full">
            <h2 className="flex text-2xl md:text-5xl leading-8 font-semibold ">
              <FormattedMessage {...messages.header} />
            </h2>
            <p className="text-lg break-word">
              <FormattedMessage {...messages.body} />
            </p>
            <Button type="primary" size="lg" LeadingIcon={HomeIcon} onClick={() => history.push('/')}>
              <FormattedMessage {...messages.home} />
            </Button>
          </div>
          <img src={Img} alt="Not Found" className="md:max-w-xl lg:max-w-2xl md:h-full hidden md:block -mt-20 mr-10" />
        </div>
      </div>
    </div>
  );
}

NotFound.propTypes = {};

export default memo(NotFound);
