export const UPDATE_USER_QUERY = `
mutation updateUser($payload: auth_update_user_input!){
  auth_update_user(data: $payload){
    error_message
    success
  }
}
`;

export const FETCH_FILE_URL_QUERY = `
query file_download_file($id: Int!) {
  file_download_file(id: $id) {
    resource_url
    id
  }
}
`;

export const UPDATE_PASSWORD_QUERY = `
mutation changePassword($payload: auth_update_user_password_input!) {
  auth_update_user_password (data: $payload) {
    error_message
    success
  }
}`;
