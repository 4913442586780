/* eslint-disable max-len */
/**
 *
 * Input
 *
 */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.css';
export function Input(props) {
  const {
    label,
    value,
    help,
    error,
    hiddenLabel,
    name,
    type,
    defaultValue,
    placeholder,
    LeadingIcon,
    TrailingIcon,
    leadingAddon,
    trailingAddon,
    inlineAddon,
    hint,
    onChange,
    trailingIconOnClick,
    leadingIconOnClick,
    disabled,
    autofocus,
    onBlur,
    highlight,
    ...rest
  } = props;

  useEffect(() => {
    if (autofocus) document.getElementById(`input-${name}`).focus();
  }, []);
  return (
    <>
      <div className="flex justify-between">
        {label && (
          <label
            htmlFor={`input-${name}`}
            className={`block text-sm font-medium text-gray-700 ${hiddenLabel ? 'sr-only' : ''}`}
          >
            {label}
          </label>
        )}
        {hint && (
          <span className="text-sm text-gray-500" id={`${name}-hint`}>
            {hint}
          </span>
        )}
      </div>

      <div className="relative flex mt-1 rounded-md shadow-sm">
        {LeadingIcon && (
          <div
            className={classNames(
              'absolute inset-y-0 left-0 pl-3 flex items-center',
              leadingIconOnClick && !disabled ? 'cursor-pointer z-10' : '',
              disabled ? 'cursor-not-allowed' : '',
            )}
            aria-hidden="true"
            onClick={leadingIconOnClick}
            data-testid="leading-icon"
          >
            <LeadingIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
        )}

        {inlineAddon && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{inlineAddon}</span>
          </div>
        )}

        {leadingAddon && (
          <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
            {leadingAddon}
          </span>
        )}
        <input
          {...rest}
          value={value}
          onKeyDown={event => {
            if (type === 'number' && ['-', '+', 'e'].includes(event.key)) event.preventDefault();
          }}
          type={type}
          name={name}
          onChange={e => onChange(e.target.value)}
          onBlur={e => {
            if (onBlur) onBlur(e.target.value);
          }}
          id={`input-${name}`}
          key={`input-${name}`}
          disabled={disabled}
          className={classNames(
            'flex-1 min-w-0 block w-full sm:text-sm focus:outline-none',
            LeadingIcon ? 'pl-10' : '',
            TrailingIcon ? 'pr-10' : '',
            inlineAddon && (inlineAddon.length > 2 ? 'pl-16' : 'pl-8'),
            error
              ? 'focus:ring-red-800 focus:border-red-800 border-red-800 text-gray-900 placeholder-red-800'
              : 'focus:ring-sky-600 focus:border-sky-600 border-gray-300 text-gray-900 placeholder-neutral-400',
            disabled ? 'text-gray-400' : '',
            highlight ? 'border-green-700' : '',
            leadingAddon ? 'rounded-r-md rounded-none' : 'rounded-md',
          )}
          placeholder={placeholder}
          defaultValue={defaultValue}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
        />
        {trailingAddon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{trailingAddon}</span>
          </div>
        )}
        {TrailingIcon && !error && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 pr-3 flex items-center',
              !trailingIconOnClick ? 'pointer-events-none' : 'z-10 cursor-pointer',
            )}
            aria-hidden="true"
            onClick={trailingIconOnClick}
            data-testid="trailing-icon"
          >
            <TrailingIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
      </div>
      {help && (
        <p className="mt-2 text-sm text-gray-500" id={`${name}-description`}>
          {help}
        </p>
      )}
      {error && (
        <p className="mt-2 ml-1 text-sm leading-5 text-red-800" id={`${name}-description`}>
          {error}
        </p>
      )}
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hiddenLabel: PropTypes.bool,
  LeadingIcon: PropTypes.object,
  TrailingIcon: PropTypes.object,
  help: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  inlineAddon: PropTypes.bool,
  trailingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  leadingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  trailingIconOnClick: PropTypes.func,
  leadingIconOnClick: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['email', 'color', 'number', 'password', 'url', 'color', 'text']),
  autofocus: PropTypes.bool,
  highlight: PropTypes.bool,
};

Input.defaultProps = {
  hiddenLabel: false,
  inlineAddon: false,
  type: 'text',
  autofocus: false,
};
export default memo(Input);
