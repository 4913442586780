/**
 *
 * ActionContext
 *
 */

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { selectAction } from 'containers/Action/selectors';
import reducer from 'containers/Action/reducer';
import saga from 'containers/Action/saga';
import * as actions from 'containers/Action/actions';
import * as queries from 'containers/Action/queries';
// import { triggerToast } from 'components/base/Notification';
import { selectAuthEvaluators, selectMetaData } from 'containers/App/selectors';

const ActionContext = React.createContext({
  state: {},
  action: {
    ...actions,
  },
  queries: {
    ...queries,
  },
});

function ActionProvider({ children }) {
  useInjectReducer({ key: 'action', reducer });
  useInjectSaga({ key: 'action', saga });

  const state = useSelector(selectAction);
  const { authUsers, timezones, providers } = useSelector(selectMetaData);
  const authEvaluators = useSelector(selectAuthEvaluators);
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const showNotification = message => {
    // triggerToast({
    //   variant: message.variant,
    //   setting: { position: 'top-right' },
    //   message,
    // });
  };

  const handleSubmit = ({ payload, action, callback = {}, additionalContext }) => {
    dispatch(action({ payload, callback, additionalContext }));
  };

  useEffect(() => {
    dispatch(actions.initialize());
  }, []);

  const value = useMemo(
    () => ({
      state: {
        ...state,
        authUsers,
        timezones,
        providers,
        authEvaluators,
      },
      dispatch,
      action: {
        ...actions,
      },
      queries: {
        ...queries,
      },
      showNotification,
      onSubmit: handleSubmit,
    }),
    [state, dispatch, actions, queries, showNotification, handleSubmit, authUsers],
  );

  return <ActionContext.Provider value={value}>{children}</ActionContext.Provider>;
}

ActionProvider.propTypes = {};

ActionProvider.defaultProps = {};
export const ActionConsumer = ActionContext.Consumer;

export { ActionProvider };

export default ActionContext;
