import { defineMessages } from 'react-intl';

export const scope = 'app.components.DownloadTemplate';

export default defineMessages({
  download: {
    id: `${scope}.download`,
    defaultMessage: 'Download',
  },
});
