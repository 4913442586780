import React, { useRef, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCamera } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { fileTypes, handleImageUpload } from 'utils/imageUploadHelpers';
import { handleEnterKeyPress } from 'utils/helpers';
import Button from 'components/base/Button';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { updateUser } from 'containers/Action/actions';
import { useDispatch } from 'react-redux';
import { uploadFileAction } from 'containers/QuestionListView/Questions/actions';
import reducer from 'containers/QuestionListView/Questions/reducer';
import saga from 'containers/QuestionListView/Questions/saga';
import actionReducer from 'containers/Action/reducer';
import actionSaga from 'containers/Action/saga';
import { fetchUserData } from 'containers/App/actions';
import messages from './messages';
import ActionContext from '../../../../../../contexts/ActionContext';

function ProfilePic(props) {
  useInjectReducer({ key: 'questions', reducer });
  useInjectSaga({ key: 'questions', saga });
  useInjectReducer({ key: 'action', reducer: actionReducer });
  useInjectSaga({ key: 'action', saga: actionSaga });
  const dispatch = useDispatch();
  const { setEditProfilePic, supportedFileTypes = fileTypes, data } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const { showNotification } = useContext(ActionContext);
  const [loading, toggleLoading] = useState(false);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const storeSelectedFile = file => {
    setSelectedFile(file);
  };

  // eslint-disable-next-line no-unused-vars
  const fileUploadError = error => {
    // catchError(error);
  };

  const updateProfilePic = res => {
    const payload = {
      profile_pic_file_id: get(res?.file_upload_file, 'id'),
    };

    toggleLoading(true);
    dispatch(
      updateUser({
        payload,
        callback: {
          onSuccess: () => {
            dispatch(fetchUserData());
            toggleLoading(false);
            showNotification({
              variant: 'success',
              title: <FormattedMessage {...messages.success_message_title} />,
              summary: <FormattedMessage {...messages.success_message_summary} />,
            });
            setEditProfilePic(false);
          },
          onError: error => {
            toggleLoading(false);
            showNotification({
              variant: 'danger',
              title: <FormattedMessage {...messages.error_message_title} />,
              summary: (
                <>
                  <FormattedMessage {...messages.error_message_summary} />, <span>{error?.errorMessage}</span>{' '}
                </>
              ),
            });
          },
        },
      }),
    );
  };

  const handleSubmit = () => {
    const uploadFilePayload = {
      file: selectedFile,
      additionalParameter: {
        file_type_id: 6, // defined in the backend for picture
        owner_id: data?.userDetails?.id,
      },
    };
    toggleLoading(true);
    dispatch(
      uploadFileAction({
        payload: uploadFilePayload,
        callback: {
          onSuccess: res => {
            updateProfilePic(res);
          },
          onError: error => {
            toggleLoading(false);
            showNotification({
              variant: 'danger',
              title: <FormattedMessage {...messages.error_message_title} />,
              summary: (
                <>
                  <FormattedMessage {...messages.error_message_summary} />.{' '}
                  <span>{error?.errorMessage || error?.message}</span>
                </>
              ),
            });
          },
        },
      }),
    );
  };
  return (
    <div className="p-2">
      <div className="flex flex-col items-center justify-center">
        {data?.userDetails?.url || selectedFile ? (
          <div className="relative w-[256px] h-[256px] rounded-full overflow-hidden">
            <img
              src={selectedFile ? URL.createObjectURL(selectedFile) : data?.userDetails?.url}
              alt="Selected"
              className="object-cover object-center w-full h-full rounded-full"
              loading="lazy"
            />
            <div
              className={classNames(
                'absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center ',
                'bg-black bg-opacity-50 text-white text-lg rounded-full opacity-0',
                'transition-opacity duration-300 hover:opacity-100',
              )}
              onClick={handleDivClick}
              onKeyDown={e => handleEnterKeyPress(e, handleDivClick)}
              role="button"
              tabIndex="-1"
            >
              <FontAwesomeIcon icon={faCamera} className="text-5xl" />
              <div className="mt-2 text-base">
                <FormattedMessage {...messages.changeProfilePhoto} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'border w-64 h-64 rounded-full flex flex-col items-center justify-center bg-gray-200 ',
              'cursor-pointer',
            )}
            onClick={handleDivClick}
            onKeyDown={e => handleEnterKeyPress(e, handleDivClick)}
            role="button"
            tabIndex="-1"
            data-testid="uploadProfilePicture"
          >
            <FontAwesomeIcon icon={faPlus} className="mb-4 text-5xl font-thin text-gray-500" />
            <div className="text-base text-gray-500">
              {' '}
              <FormattedMessage {...messages.addProfilePhoto} />
            </div>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={event =>
            handleImageUpload({
              event,
              callback: { onSuccess: storeSelectedFile, onError: fileUploadError },
              supportedFileTypes,
              ignoreConversion: true,
            })
          }
          style={{ display: 'none' }}
          accept="image/*"
          data-testid="input-file"
        />
        <div className="flex items-center mt-8">
          <div className="w-24">
            <Button type="tertiary" isFullWidth onClick={() => setEditProfilePic(prev => !prev)}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
          <div className="ml-4 w-28">
            <Button onClick={handleSubmit} isFullWidth disabled={!selectedFile} loading={loading}>
              <FormattedMessage {...messages.save} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePic;
