/**
 *
 * UserProvider
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext({
  client: {},
  user: {},
});

function UserProvider(props) {
  const { children, client, user } = props;
  const value = useMemo(
    () => ({
      client,
      user,
    }),
    [client, user],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
UserProvider.propTypes = {
  client: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.any,
};

UserProvider.defaultProps = {
  client: {},
  user: {},
};
export const UserConsumer = UserContext.Consumer;

export { UserProvider };

export default UserContext;
