/*
 *
 * Action reducer
 *
 */
import { produce } from 'immer';
// import recurrence from 'utils/data/recurrence';
import * as types from './constants';

export const initialState = {
  isLoading: false,
  isError: null,
  timezones: [],
  providers: [],
  // recurrence,
};

/* eslint-disable default-case, no-param-reassign */
const actionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.STORE_FETCHED_TIMEZONES: {
        draft.timezones = action.payload;
        break;
      }
      case types.STORE_FETCHED_PROVIDER: {
        draft.providers = action.payload;
        break;
      }
    }
  });

export default actionReducer;
