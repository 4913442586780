/**
 *
 * Button
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FormattedMessage } from 'react-intl';
export function Button(props) {
  const { size, children, type, loading, LeadingIcon, TrailingIcon, disabled, tooltip, ...rest } = props;

  const sizeMap = {
    xs: 'md:px-2 md:py-1.5 px-0.5 py-0.5 text-xs font-medium',
    sm: 'md:px-5 md:py-2.5 px-1 py-1 text-sm leading-5 font-medium',
    md: 'md:px-3 md:py-2 px-1.5 py-1.5 text-sm font-medium ',
    l: 'md:px-6 md:py-3 px-2.5 py-3 text-sm font-medium leading-4',
    base: 'md:px-5 md:py-2 px-1.5 py-1.5 text-base font-medium leading-5',
    lg: 'md:px-4 md:py-3 px-2 py-2 text-base font-semibold',
    xl: 'md:px-6 md:py-2 px-2 py-2 text-lg font-semibold',
    xxl: 'md:px-6 md:py-2 px-2 py-2 text-sm font-medium w-full',
    xxxl: 'md:px-6 md:py-2 px-2 py-2 text-xs  font-medium rounded-r-full rounded-l-full',
  };

  const iconSizeMap = {
    xs: { leading: '-ml-1.5 mr-0.5 h-3 w-3', trailing: 'ml-0.5 -mr-0.5 h-3 w-3', loading: 'h-3 w-3' },
    sm: { leading: '-ml-2 mr-0.5 h-3 w-4', trailing: 'ml-1 -mr-0.5 h-4 w-4', loading: 'h-4 w-4' },
    md: { leading: '-ml-1 mr-1 h-4 w-4 ', trailing: 'ml-1 -mr-1 h-4 w-4', loading: 'h-4 w-4' },
    lg: {
      leading: '-ml-2 mr-1 md:h-6 md:w-6 h-4 w-4',
      trailing: 'ml-2 -mr-1 h-6 w-6',
      loading: 'md:h-6 md:w-6 h-3 w-3',
    },
    xl: {
      leading: '-ml-2 mr-1 md:h-8 md:w-8 h-4 w-4',
      trailing: 'ml-2 -mr-1.5 h-8 w-8',
      loading: 'md:h-8 md:w-8 h-3 w-3',
    },
    xxl: {
      leading: 'ml-1 mr-2 md:h-5 md:w-4 h-5 w-5',
    },
    xxxl: { leading: '-ml-1 mr-1 h-4 w-4 ', trailing: 'ml-1 -mr-1 h-4 w-4', loading: 'h-4 w-4' },
    l: {
      leading: 'ml-1 mr-2 md:h-5 md:w-5 h-5 w-5',
      trailing: 'ml-2 mr-0.5 h-8 w-8',
      loading: 'md:h-8 md:w-8 h-3 w-3',
    },
  };

  const colorMap = {
    primary: {
      base: 'focus:ring-blue-900 bg-blue-900 hover:bg-blue-800 text-white',
      disabled: 'bg-blue-900 bg-opacity-50 text-white',
    },
    danger: { base: 'focus:ring-red-800 bg-red-800 hover:bg-red-700 text-white', disabled: 'bg-stone-300' },
    secondary: {
      base: 'focus:ring-blue-900 text-blue-900 bg-white shadow-sm rounded-md',
      disabled: 'border border-blue-900 border-opacity-50 text-white',
    },
    selectable: {
      base: 'border border-blue-900 bg-white text-blue-900 ',
    },
    selected: { base: 'bg-blue-900 text-white border border-blue-900' },
    tertiary: { base: 'bg-blue-900 text-white border-2 border-white-900' },
  };

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={classNames(
          'inline-flex items-center border justify-center align-middle ',
          type === 'secondary' || type === 'selectable' ? 'border-blue-900' : 'border-transparent',

          get(sizeMap, size),
          disabled ? 'pointer-events-none' : '',
          'rounded-lg shadow-sm focus:outline-none',
          get(colorMap, `${type}.${disabled ? 'disabled' : 'base'}`),
          'focus:ring-2 focus:ring-offset-2',
        )}
        data-for={get(tooltip, 'id')}
        data-tip={!!tooltip}
        {...rest}
      >
        {loading && (
          <svg
            className={classNames(
              'animate-spin -ml-1 mr-3',
              type === 'transparent' ? 'text-black' : 'text-white',
              get(iconSizeMap, `${size}.loading`),
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
            7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        {!loading && LeadingIcon && (
          <div className={classNames(children ? '' : 'ml-2')}>
            <LeadingIcon className={get(iconSizeMap, `${size}.leading`)} />
          </div>
        )}
        <div>{children}</div>
        {!loading && TrailingIcon && <TrailingIcon className={classNames(get(iconSizeMap, `${size}.trailing`))} />}
      </button>
      {tooltip && (
        <ReactTooltip id={get(tooltip, 'id')} place="bottom" effct="solid">
          <FormattedMessage {...tooltip} />
        </ReactTooltip>
      )}
    </>
  );
}

Button.propTypes = {
  loading: PropTypes.bool,
  tooltip: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  TrailingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  LeadingIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'selectable', 'selected', 'tertiary']),
  size: PropTypes.oneOf(['sm', 'xs', 'md', 'lg', 'xl', 'l', 'base', 'xxl']),
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  type: 'primary',
  size: 'md',
};

export default memo(Button);
