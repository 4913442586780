/**
 *
 * Loading
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { FormattedMessage } from 'react-intl';
import { random } from 'lodash';

import Img1 from 'images/slow/load_more.svg';
import Img2 from 'images/slow/loading.svg';
import Img3 from 'images/slow/progress_indicator.svg';
import Img4 from 'images/slow/speed_test.svg';

import messages from './messages';

export function Loading(props) {
  const { pacman, show } = props;
  // useEffect(() => {
  //   let t;
  //   if (!hideMessage && timeout) {
  //     t = setTimeout(() => toggleShow(true), timeout);
  //   }
  //   return () => {
  //     if (t) clearTimeout(t);
  //   };
  // }, []);
  const images = [Img1, Img2, Img3, Img4];
  return (
    <div
      data-testid="loading-component-0"
      className="flex h-full w-full justify-center align-middle items-center flex-col space-y-6 relative"
    >
      {!show ? (
        <>
          {pacman ? (
            <Spinner name="pacman" color="#10B981" fadeIn="full" data-testid="spinner" />
          ) : (
            <svg
              className="animate-spin-slow  -ml-1 mr-3 text-zinc-700 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962
              7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          )}
        </>
      ) : (
        <div className="flex justify-center align-middle items-center flex-col space-y-4">
          <img src={images[random(0, 3)]} className="h-32 " alt="slow" />
          <p className=" animate-pulse text-lg">
            <FormattedMessage {...messages.body} />
            <span className=" "> ...</span>
          </p>
        </div>
      )}
    </div>
  );
}

Loading.propTypes = {
  pacman: PropTypes.bool,
  hideMessage: PropTypes.bool,
  timeout: PropTypes.number,
  show: PropTypes.bool,
};

Loading.defaultProps = {
  timeout: 10000,
  hideMessage: true,
  pacman: false,
  show: false,
};

export default memo(Loading);
