import { takeLatest, setContext, put, call, cancel, select, takeEvery, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { map, concat, get } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import { parseJwt } from 'utils/helpers';

import Apollo from 'utils/apollo';

import {
  GLOBAL_ERROR,
  LOGGED_IN,
  LOGIN_COMPLETE,
  CLEAR_ERROR,
  STOP_TASK,
  STOPPED_TASK,
  INIT_CLIENT,
  INIT_COMPLETE,
  INIT_LOGIN_CLIENT,
  FETCH_USER_DATA,
  STORE_USER_DATA,
  ERROR_ON_USER_DATA,
  STORE_FETCHED_TIMEZONES,
  FETCH_TIME_ZONE,
  UPDATE_TIME_ZONE,
} from './constants';

import { makeSelectTasks } from './selectors';
import { init, userProfileDetails, fetchTimeZoneDate } from './api';

// Individual exports for testing
export function* loginSaga({ client /* errorCallback */ }) {
  try {
    yield setContext({ client });
    yield put({ type: LOGIN_COMPLETE, client });
  } catch (error) {
    if (process.env.NODE_ENV !== 'production' && console) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    Sentry.captureException(error);
    yield put({ type: GLOBAL_ERROR, error });
  }
}

export function* clearErrorSaga({ history }) {
  try {
    yield call(history.push, '/logout');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* stopTaskSaga({ task }) {
  try {
    if (task && task.isRunning()) {
      yield cancel(task);
    }
    yield put({ type: STOPPED_TASK, task });
  } catch (error) {
    Sentry.captureException(error);
    yield put({ type: GLOBAL_ERROR, error });
  }
}

export function* locationChangeSaga() {
  const { tasks } = yield select(makeSelectTasks(), { key: 'tasks' });

  yield all(concat(map(tasks, task => put({ type: STOP_TASK, task }))));
}

export function* handleUser({ user }) {
  let client = {};
  let u = {};
  if (user) {
    client = Apollo.createClient({
      graphToken: user.getIdToken(),
      errorCallback: () => {},
      // role: get(th, '0'),
    });
    u = {
      displayName: user.displayName,
      roles: [],
      accountName: '',
      userName: u.username,
      organization: u.tenantId,
      isLoggedIn: !u.isAnonymous,
    };
  }
  yield put({ type: INIT_COMPLETE, user: u, client });
}

export function getTokenAttributes(access_token) {
  const token = parseJwt(access_token);
  const sign_in_attributes = token?.firebase?.sign_in_attributes;
  return {
    displayName:
      (sign_in_attributes && sign_in_attributes['http://schemas.microsoft.com/identity/claims/displayname']) || '',
    userName:
      (sign_in_attributes && sign_in_attributes['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']) ||
      '',
    organization: token['https://hasura.io/jwt/claims']['X-Hasura-Tenant-Id'] || '',
    userId: token['https://hasura.io/jwt/claims']['X-Hasura-User-Id'] || '',
  };
}

export function* handleUserToken() {
  const client = {};
  let u = {};
  let parsedToken;
  const token = localStorage.getItem('tv.ae') && JSON.parse(localStorage.getItem('tv.ae')).access_token;
  if (token) {
    parsedToken = getTokenAttributes(token);
    u = {
      displayName: parsedToken.displayName,
      roles: [],
      accountName: '',
      userName: parsedToken.userName,
      organization: parsedToken.organization,
      isLoggedIn: true,
      isAnonymous: false,
      userId: parsedToken.userId,
    };
  }
  yield put({ type: INIT_COMPLETE, user: u, client });
}

export function* initSaga() {
  yield call(init);
}

export function* fetchUserDataSaga() {
  try {
    const data = yield call(userProfileDetails, '', true);
    if (data) {
      yield put({ type: STORE_USER_DATA, payload: data?.auth_user_me });
      yield put({ type: FETCH_TIME_ZONE });
    }
  } catch (error) {
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      yield put({ type: ERROR_ON_USER_DATA, error });
    }
  }
}

export function* fetchDataTimeZone() {
  try {
    const response = yield call(fetchTimeZoneDate);
    console.log('TimeZone', response);
    yield put({ type: STORE_FETCHED_TIMEZONES, payload: response.data.md_timezone });
  } catch (error) {
    console.log('Error', error);
  }
}

export function* updateTimeZone({ timezone }) {
  localStorage.setItem('tv.userTimeZone', JSON.stringify(timezone));
}

// eslint-disable-next-line no-empty-function
export default function* AppSaga() {
  yield takeLatest(INIT_CLIENT, initSaga);
  yield takeLatest(INIT_LOGIN_CLIENT, handleUserToken);
  yield takeLatest(LOGGED_IN, loginSaga);
  yield takeLatest(CLEAR_ERROR, clearErrorSaga);
  yield takeLatest(FETCH_USER_DATA, fetchUserDataSaga);
  yield takeLatest(FETCH_TIME_ZONE, fetchDataTimeZone);
  yield takeLatest(UPDATE_TIME_ZONE, updateTimeZone);

  yield takeLatest(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(STOP_TASK, stopTaskSaga);
}
