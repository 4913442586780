/**
 *
 * SidePanel
 *
 */

import React, { memo, Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, CheckCircleIcon, BanIcon } from '@heroicons/react/outline';
import { isFunction, get } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Button from '../Button';

import messages from './messages';

export function SidePanel(props) {
  const {
    backgroundOverlay,
    outsideCloseButton,
    onSave,
    children,
    title,
    show,
    onClose,
    LeadingIcon,
    size,
    onClickTitleIcon,
    renderTitleIcon,
    renderFooter,
    footerType,
    titleData,
    disableOutsideClick,
    hasClose,
    customizeHeader,
    onKeyClose,
  } = props;
  const [open, setOpen] = useState(show);
  const sizeMap = {
    md: 'max-w-md',
    xl: 'max-w-xl',
    xxl: 'max-w-2xl',
    xxxl: 'max-w-3xl',
  };
  const footerAlign = {
    between: 'justify-between',
    start: 'justify-start',
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);
  const closeFunc = () => {
    if (onKeyClose) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const titleClickHandler = () => {
    if (onClickTitleIcon) onClickTitleIcon();
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0" onClose={closeFunc}>
        <div data-testid="side_panel" className="absolute inset-0 ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                'absolute inset-0',
                backgroundOverlay ? 'bg-gray-500 bg-opacity-75 transition-opacity' : '',
                disableOutsideClick ? 'pointer-events-none' : '',
              )}
              data-testid="overlay"
            />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 mt-16 pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`pointer-events-auto w-screen ${get(sizeMap, size)}  overflow-auto`}>
                {outsideCloseButton && (
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 flex pt-4 pr-2 -ml-10 sm:-ml-8 md:-ml-2 sm:pr-4">
                      <button type="button" className="close-panel" onClick={closeFunc} data-testid="close-panel">
                        <span className="sr-only">Close panel</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                )}

                <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                  <div className="flex flex-col flex-1 min-h-0">
                    <div
                      className={classNames(
                        'flex items-start justify-between top-0 pt-3 sticky bg-gray-50',
                        'w-full  mb-3 shadow-xs border-b-2 border-gray-100',
                      )}
                    >
                      <div className="flex items-start justify-between w-full h-16 px-6 mb-2 align-middle">
                        {title && (
                          <Dialog.Title className="dialog">
                            <div className="flex flex-col">
                              <div className="flex items-center justify-start">
                                <div
                                  className="pointer-events-auto"
                                  onClick={titleClickHandler}
                                  onKeyDown={titleClickHandler}
                                  role="button"
                                  tabIndex="0"
                                >
                                  {renderTitleIcon && <div className="mr-2">{renderTitleIcon()}</div>}
                                  {LeadingIcon && (
                                    <LeadingIcon data-testid="side-titleicon" className="w-6 mr-2 h-7 text-zinc-700" />
                                  )}
                                </div>
                                <div className="pl-1 text-lg text-left">{title}</div>
                              </div>
                              {titleData && (
                                <div className="pl-1 text-sm capitalize">
                                  <span className="font-medium">{titleData.type}</span> Question ID : {titleData.id}
                                </div>
                              )}
                            </div>
                          </Dialog.Title>
                        )}
                        {/* customize header component */}
                        {isFunction(customizeHeader) && <div>{customizeHeader()}</div>}
                        {!outsideCloseButton && (
                          <button type="button" className="close-func" onClick={closeFunc}>
                            <span className="sr-only">
                              <FormattedMessage {...messages.close_panel} />
                            </span>
                            {hasClose && (
                              <XIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                                data-testid="rightside-crossicon"
                                onClick={() => onClose()}
                              />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="flex-1 px-4 py-2 mt-1 overflow-y-scroll sm:px-6">{children}</div>
                  </div>
                  {isFunction(renderFooter) && footerType && (
                    <div
                      data-testid="footer_rendered"
                      className={`flex flex-shrink-0 ${get(footerAlign, footerType)} px-5 py-4 space-x-6 shadow-md`}
                    >
                      {renderFooter()}
                    </div>
                  )}
                  {isFunction(onSave) && (
                    <div className="flex justify-start flex-shrink-0 px-4 py-4 space-x-6">
                      <Button type="danger" size="md" LeadingIcon={BanIcon} onClick={closeFunc}>
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                      <Button
                        type="primary"
                        size="md"
                        LeadingIcon={CheckCircleIcon}
                        onClick={() => {
                          onSave();
                          closeFunc();
                        }}
                      >
                        <FormattedMessage {...messages.save} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SidePanel.propTypes = {
  backgroundOverlay: PropTypes.bool,
  outsideCloseButton: PropTypes.bool,
  onSave: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  show: PropTypes.bool,
  LeadingIcon: PropTypes.object,
  size: PropTypes.oneOf(['md', 'xxl', 'xxxl']),
  onClickTitleIcon: PropTypes.func,
  successLabel: PropTypes.string,
  onChangeCheckbox: PropTypes.func,
  renderTitleIcon: PropTypes.func,
  renderFooter: PropTypes.func,
  customizeHeader: PropTypes.func,
  footerType: PropTypes.oneOf(['between', 'start']),
  titleData: PropTypes.object,
  disableOutsideClick: PropTypes.bool,
  hasClose: PropTypes.bool,
};

SidePanel.defaultProps = {
  backgroundOverlay: false,
  outsideCloseButton: false,
  show: false,
  onSave: false,
  size: 'md',
  disableOutsideClick: false,
  hasClose: true,
};

export default memo(SidePanel);
