import { concat } from 'lodash';
import XLSX from 'xlsx';

export const download = async ({ columns, rows, name }) => {
  const ws = XLSX.utils.aoa_to_sheet(concat([columns], rows));
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Export');
  /* generate XLSX file and send to client */
  return XLSX.writeFile(wb, `${name}.xlsx`, { Props: { Author: 'Talview' } });
};

export default download;
