/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'app/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'app/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const GLOBAL_LOADING = 'app/App/GLOBAL_LOADING';
export const GLOBAL_ERROR = 'app/App/GLOBAL_ERROR';
export const LOGGED_IN = 'app/App/LOGGED_IN';
export const INIT_CLIENT = 'app/App/INIT_CLIENT';
export const INIT_LOGIN_CLIENT = 'app/App/INIT_LOGIN_CLIENT';
export const INIT_COMPLETE = 'app/App/INIT_COMPLETE';
export const LOGIN_COMPLETE = 'app/App/LOGIN_COMPLETE';
export const CLEAR_ERROR = 'app/App/CLEAR_ERROR';
export const SAVE_STATE_ACTION = 'app/App/SAVE_STATE_ACTION';
export const FETCH_USER_DATA = 'app/App/FETCH_USER_DATA';
export const STORE_USER_DATA = 'app/App/STORE_USER_DATA';
export const ERROR_ON_USER_DATA = 'app/App/ERROR_ON_USER_DATA';

export const SET_TASK_ACTIVE = 'app/App/SET_TASK_ACTIVE';
export const STOP_TASK = 'app/App/STOP_TASK';
export const STOPPED_TASK = 'app/App/STOPPED_TASK';
export const STORE_FETCHED_TIMEZONES = 'app/App/STORE_FETCHED_TIMEZONES';
export const FETCH_TIME_ZONE = 'app/App/FETCH_TIME_ZONE';
export const UPDATE_TIME_ZONE = 'UPDATE_TIME_ZONE';
