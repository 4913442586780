/* eslint-disable no-console */
import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';

export const growthbook = new GrowthBook({
  apiHost: process.env.GB_API_HOST || 'https://gb-api.talview.com',
  clientKey: process.env.GB_CLINT_KEY || 'sdk-HiLecj4FBKD0bZz',
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback(experiment, result) {
    Mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      $source: 'growthbook',
    });
  },
});
