import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Content from './Content';
import messages from './messages';
import { options } from './options';

const AccountSettingsContainer = props => {
  const { handleClose, userDetails } = props;

  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog data-testid="closeDialog" as="div" className="relative z-50" onClose={handleClose} initialFocus>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    'transform rounded bg-white pl-6 pt-6',
                    'text-left align-middle shadow-xl transition-all ',
                  )}
                >
                  <Dialog.Title as="h3" className="flex items-center justify-between flex-1 pb-5 pr-6">
                    <span className="text-xl font-semibold text-gray-900">
                      <FormattedMessage {...messages.header} />{' '}
                    </span>

                    <FontAwesomeIcon
                      icon={faXmark}
                      className={classNames(
                        'ml-2 cursor-pointer h-5 w-5 text-gray-900',
                        'hover:bg-gray-100 p-[7px]',
                        'active:bg-gray-200',
                      )}
                      onClick={() => handleClose && handleClose()}
                      data-testid="confirm-modal-close"
                      tabIndex="-1"
                    />
                  </Dialog.Title>

                  <>{React.cloneElement(<Content options={options} userDetails={userDetails} />)}</>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AccountSettingsContainer;
