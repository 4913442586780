import Auth from 'utils/auth';
import { formatData } from 'utils/helpers';
import {
  GET_DROPDOWN_ITEMS,
  UPDATE_QUESTION,
  INSERT_BULK,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  GET_QUESTION_LANGUAGE,
} from './queries';
import { INSERT_CODEPAIR_QUESTION } from './CodePair/queries';
import { INSERT_CODING_QUESTION } from './Coding/queries';
import { INSERT_ESSAY_QUESTION } from './Essay/queries';
import INSERT_MCQ_QUESTION from './MCQ/queries';
import INSERT_MCA_QUESTION from './MCA/queries';
import INSERT_VIDEO_QUESTION from './VideoInterview/queries';
import INSERT_LIVE_QUESTION from './LiveInterview/queries';

let dropdownData = {};
export const getDropDownData = () => dropdownData;

export const makeRequest = async (forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.query({
    query: GET_DROPDOWN_ITEMS,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
  });
  dropdownData = {
    level: data.qbm_question_level.map(b => b.name) || [],
    skills: data.qbm_skill.map(b => b.name) || [],
    bloomsTaxonamy: data.qbm_blooms_taxonomy.map(b => b.name) || [],
    languages: data.qbm_language.map(b => b.value) || [],
    status: data.qbm_question_status.map(b => b.name) || [],
    orgSkills: data.qbm_skill || [],
  };
  return {
    data: {
      level: formatData(data.qbm_question_level),
      skills: formatData(data.qbm_skill),
      bloomsTaxonamy: formatData(data.qbm_blooms_taxonomy),
      languages: formatData(data.qbm_language),
      status: formatData(data.qbm_question_status, true),
      createdBy: formatData(data?.qbm_question?.map(item => item?.userByCreatedBy)),
    },
  };
};

export const getQuestionLanguage = async (selectedLanguage, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.query({
    query: GET_QUESTION_LANGUAGE,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: selectedLanguage,
  });
  return {
    data: formatData(data.qbm_language),
  };
};

export const getInsertQuery = type => {
  if (type === 'coding') return INSERT_CODING_QUESTION;
  if (type === 'code_pair') return INSERT_CODEPAIR_QUESTION;
  if (type === 'multiple_choice') return INSERT_MCQ_QUESTION;
  if (type === 'multiple_choice_answer') return INSERT_MCA_QUESTION;
  if (type === 'live') return INSERT_LIVE_QUESTION;
  if (type === 'video') return INSERT_VIDEO_QUESTION;
  return INSERT_ESSAY_QUESTION;
};

export const insertQuestion = async (query, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.mutate({
    mutation: getInsertQuery(query.type),
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: query,
  });
  return {
    data: { id: data.id },
  };
};

export const bulkImport = async (query, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.mutate({
    mutation: INSERT_BULK,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: { insertarray: query },
  });
  return {
    data: { id: data.id },
  };
};

export const updateQuestion = async (query, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.mutate({
    mutation: UPDATE_QUESTION,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: query,
  });
  return {
    data: { id: data.id },
  };
};

export const uploadFile = async (query, extension, forceRefresh = true) => {
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.mutate({
    mutation: UPLOAD_FILE,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: { filename: query, type: extension },
  });
  return {
    data: { id: data.file_upload_file.id, file: data.file_upload_file.data },
  };
};

export const downloadFile = async (query, forceRefresh = true) => {
  if (!+query) {
    return {
      data: { resourceURL: '' },
    };
  }
  const apolloClient = await Auth.client();
  const { data } = await apolloClient.query({
    query: DOWNLOAD_FILE,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    variables: { id: +query },
  });
  return {
    data: { resourceURL: data.file_get_file.resource_url },
  };
};
