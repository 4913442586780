export const FETCH_QUESTIONLISTVIEW_DATA = 'app/FETCH_QUESTIONLISTVIEW_DATA';
export const STORE_QLV_DATA = 'app/STORE_QLV_DATA';
export const ERROR_ON_FETCH_QLV_DATA = 'app/ERROR_ON_FETCH_QLV_DATA';
export const QUSETION_INSERTION_COMPLETE = 'app/QUESTIONLISTVIEW/QUESTIONS/INSETION_COMPLETE';
export const ERROR_ON_QUESTION_INSERTION = 'app/QUESTIONLISTVIEW/QUESTIONS/INSETION_ERROR';
export const DELETE_QUESTION = 'app/QUESTIONLISTVIEW/DELETE_QUESTION';

export const SELECT_REQUEST_IDS = 'app/QUESTIONLISTVIEW/SELECT_REQUEST_IDS';

export const SET_NEW_ASSIGN_TO = 'app/QUESTIONLISTVIEW/SET_NEW_ASSIGN_TO';

export const ASSIGN_REVIEWER_ACTION = 'app/QUESTIONLISTVIEW/ASSIGN_REVIEWER_ACTION';

export const ASSIGN_REVIEWER_ACTION_COMPLETE = 'app/QUESTIONLISTVIEW/ASSIGN_REVIEWER_ACTION_COMPLETE';

export const ERROR_ON_ASSIGN_REVIEWER = 'app/QUESTIONLISTVIEW/ERROR_ON_ASSIGN_REVIEWER';
