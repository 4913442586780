import React, { memo } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectApp, makeSelectLocation } from 'containers/App/selectors';
import { get, find } from 'lodash';
import { selectQuestionListData } from '../../../../containers/QuestionListView/selectors';

export function Breadcrumb(props) {
  const { isStory, defaultItems } = props;
  const location = useLocation();
  const questionBankName = location?.state?.id;
  const history = useHistory();
  const data = useSelector(selectQuestionListData);

  const getPageName = id => {
    const qbRow = find(data?.questions, { question_bank_id: Number(id) });
    return qbRow?.question_bank?.name ? qbRow?.question_bank?.name : questionBankName;
  };
  const navigateToPage = async item => {
    const matchingKey = getKey();
    if (matchingKey && matchingKey !== item?.key) {
      history.push(item.href);
    }
  };

  const pages = get(props, 'location.pathname').split('/');
  const crumbs = pages?.map((obj, index) => ({
    href: `/${obj}`,
    key: index,
    label: obj,
    name: getPageName(obj),
  }));

  const breadCrumbItems = isStory ? defaultItems : crumbs;

  const getKey = () => {
    let matchingKey = null;

    if (breadCrumbItems?.length < 1) return matchingKey;
    const keyIndex = breadCrumbItems?.length;

    if (keyIndex - 1 < 0) return matchingKey;

    matchingKey = breadCrumbItems[keyIndex - 1].key;
    return matchingKey;
  };

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center lg:space-x-3">
        {breadCrumbItems?.map((item, index) => (
          <li
            style={{ display: item?.label === 'edit' ? 'none' : 'flex' }}
            key={item?.key}
            className="flex items-center"
          >
            <div className="flex items-center">
              {index === 0 ? (
                <FontAwesomeIcon icon={faHome} />
              ) : (
                <>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <span
                    href={item?.href}
                    className={classNames(
                      'text-sm py-1.5 px-2 mr-2',
                      getKey() === item.key
                        ? 'text-gray-700 font-medium'
                        : 'text-gray-400 cursor-pointer rounded ' +
                            'hover:text-gray-700 hover:bg-gray-100 border border-transparent hover:border-gray-200',
                    )}
                    onClick={() => navigateToPage(item)}
                    onKeyDown={() => navigateToPage(item)}
                    tabIndex={0}
                    role="link"
                  >
                    {Number(item?.label) ? item?.name : item?.label}
                  </span>
                </>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

const mapStateToProps = createStructuredSelector({
  page: makeSelectApp(),
  location: makeSelectLocation(),
});

const withConnect = connect(mapStateToProps);
export default memo(compose(withConnect)(Breadcrumb));
