import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'components/base/NewDropdown';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';
import Button from 'components/base/Button';
import { updateTimeZone } from 'containers/App/actions';
import { Notification } from 'components/base/Notification';
import messages from './messages';
import { selectTimeZone } from '../../../../../../../containers/App/selectors';

function ChangeLanguage() {
  const defaultTimeZones = useSelector(selectTimeZone);
  const userTimeZone = JSON.parse(localStorage.getItem('tv.userTimeZone'));
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateTimeZone({ timezone: selectedTimezone }));
    setShowMessage(true);
  };

  const defaultTimezone = useMemo(() => {
    const { zoneName } = DateTime.local();
    if ((zoneName || '').toLowerCase().includes('calcutta')) {
      return { description: 'Asia/Kolkata', value: 'Asia/Kolkata' };
    }
    return { description: zoneName, value: zoneName };
  }, []);

  const [selectedTimezone, setSelectedTimezone] = useState(userTimeZone || defaultTimezone);

  const disabled = useMemo(() => {
    if (userTimeZone?.value) return selectedTimezone?.value === userTimeZone?.value;
    return selectedTimezone?.value === defaultTimezone?.value;
  }, [userTimeZone?.value, selectedTimezone?.value, defaultTimezone?.value]);

  return (
    <div className="w-full p-6">
      <div className="pb-6 font-medium text-base leading-5 text-gray-900">
        <FormattedMessage {...messages.timezone} />
      </div>
      <div className="mb-4 font-normal leading-4 text-sm text-gray-900">
        <FormattedMessage {...messages?.select_timezone} />
      </div>
      <div className="w-[470px]">
        <Dropdown
          value={selectedTimezone}
          name="timezone-select"
          options={defaultTimeZones}
          onChange={value => setSelectedTimezone(value)}
          placeholder="Change timezone"
          labelKey="description"
          primaryKey="value"
          id="timezones"
          isSearchable
          lightBackground
          isSearchIcon
        />
      </div>

      <div className="flex gap-3 justify-end mt-10 pr-2">
        <Button size="lg" disabled={disabled} onClick={handleClick}>
          <FormattedMessage {...messages?.submit} />
        </Button>
      </div>
      {showMessage && (
        <Notification header="Timezone" type="success" timeout={2000}>
          <FormattedMessage {...messages.timezone_success_summary} />
        </Notification>
      )}
    </div>
  );
}

export default ChangeLanguage;
