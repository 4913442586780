import { find, memoize } from 'lodash';

const map = [
  {
    key: 'candidates',
    path: '/candidate',
    isRealTime: false,
  },
  {
    key: 'workflows',
    path: '/workflow',
    isRealTime: true,
  },
  {
    key: 'Workflows',
    path: '/workflow/:id/invites',
    disabled: true,
    isRealTime: true,
  },
  {
    key: 'interviews',
    path: '/interview',
    isRealTime: true,
  },
  {
    key: 'questions',
    path: '/question',
    isRealTime: true,
  },
  {
    key: 'users',
    path: '/admin/models/user',
    isRealTime: true,
  },
  {
    key: 'external assessments',
    path: '/admin/external',
    isRealTime: true,
  },
  {
    key: 'dropdown fields',
    path: '/admin/form/select-field-type',
    isRealTime: true,
  },
  {
    key: 'dropdown items',
    path: '/admin/form/select-field-type/:id',
    isRealTime: true,
  },
  {
    key: 'external invites',
    path: '/admin/external/:id/invites',
    isRealTime: true,
  },
  {
    key: 'email templates',
    path: '/admin/templates/email',
    isRealTime: true,
  },
  {
    key: 'skills',
    path: '/admin/config/skill',
    isRealTime: true,
  },
  {
    key: 'categories',
    path: '/admin/config/category',
    isRealTime: true,
  },
  {
    key: 'forms',
    path: '/admin/form/candidate',
    isRealTime: true,
  },
  {
    key: 'fields',
    path: '/admin/fields',
    isRealTime: true,
  },
  {
    key: 'notifications',
    path: '/admin/log/notification',
    isRealTime: true,
  },
  {
    key: 'parameters',
    path: '/admin/config/parameter',
    isRealTime: true,
  },
];

export function resolve(path) {
  const i = find(map, { path });
  return i ? { name: i.key || '', pathname: i.path, disabled: !!i.disabled, isRealTime: !!i.isRealTime } : null;
}

export const getPath = ({ pathname, path }) => {
  let res = resolve(pathname);
  if (!res) {
    res = resolve(path);
  }
  return res || { name: '', pathname: '/candidate', disabled: false, isRealTime: false };
};

export default memoize(getPath);
