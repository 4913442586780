/*
 *
 * Question List View reducer
 *
 */
import produce from 'immer';
import {
  FETCH_QUESTIONLISTVIEW_DATA,
  STORE_QLV_DATA,
  ERROR_ON_FETCH_QLV_DATA,
  QUSETION_INSERTION_COMPLETE,
  ERROR_ON_QUESTION_INSERTION,
  SELECT_REQUEST_IDS,
  SET_NEW_ASSIGN_TO,
  ASSIGN_REVIEWER_ACTION,
  ASSIGN_REVIEWER_ACTION_COMPLETE,
  ERROR_ON_ASSIGN_REVIEWER,
} from './constants';

export const initialState = {
  request: {
    error: null,
    isLoading: false,
    success: null,
  },
  questions: [],
  qCount: 0,
  currentPage: 1,
  selectedRequestIdsForReview: {},
  setNewAssignTo: {},
};

/* eslint-disable default-case, no-param-reassign */
export const questionListViewReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCH_QUESTIONLISTVIEW_DATA:
        draft.request.isLoading = true;
        draft.request.error = null;
        break;

      case STORE_QLV_DATA:
        draft.request.isLoading = false;
        draft.questions = action.payload.questions;
        draft.qCount = action.payload.q_count;
        draft.currentPage = action.payload.currentPage;
        draft.request.success = null;
        break;

      case ERROR_ON_FETCH_QLV_DATA: {
        draft.request.isLoading = false;
        draft.request.error = action.error;
        break;
      }
      case QUSETION_INSERTION_COMPLETE: {
        draft.request.success = action.complete;
        draft.request.error = null;
        break;
      }
      case ERROR_ON_QUESTION_INSERTION: {
        draft.request.error = action.error;
        draft.request.success = null;
        break;
      }
      case SELECT_REQUEST_IDS: {
        draft.selectedRequestIdsForReview = action.selectedRequestIdsForReview;
        break;
      }
      case SET_NEW_ASSIGN_TO: {
        draft.setNewAssignTo = action.setNewAssignTo;
        break;
      }
      case ASSIGN_REVIEWER_ACTION: {
        draft.request.isLoading = true;
        draft.request.success = false;
        draft.request.error = false;
        break;
      }
      case ASSIGN_REVIEWER_ACTION_COMPLETE: {
        draft.request.isLoading = false;
        draft.request.success = true;
        draft.request.error = null;
        break;
      }
      case ERROR_ON_ASSIGN_REVIEWER: {
        draft.request.isLoading = false;
        draft.request.success = false;
        draft.request.error = action.error;
        break;
      }
      default:
        return draft;
    }
    return draft;
  });

export default questionListViewReducer;
