/**
 *
 * TwAvatar
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, isString } from 'lodash';
import { useHistory } from 'react-router-dom';
import Photo from '../Photo';
// import styled from 'styled-components';

export function Avatar(props) {
  // eslint-disable-next-line no-unused-vars
  const { user, size, isSuper, link, showName } = props;
  const history = useHistory();
  const name = get(user, 'name', get(user, 'name', !isSuper ? 'Deleted User' : 'Super Admin'));
  const onClick = () => {
    if (link) {
      history.push(link);
    }
  };

  if (!isString(name)) return <></>;
  return (
    <div className="flex items-center space-x-1 is-align-middle">
      <div className="flex-initial" data-for={get(user, 'id', '').toString()} data-tip="true">
        <Photo name={name} onClick={onClick} aria-hidden="true" size={size} />
      </div>
    </div>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'xs', 'lg']),
  user: PropTypes.shape({
    id: PropTypes.number,
    external_id: PropTypes.string,
    email: PropTypes.string,
    username: PropTypes.string,
    profilesByUserId: PropTypes.array,
  }),
  // showTooltip: PropTypes.bool,
  link: PropTypes.string,
  isSuper: PropTypes.bool,
  showName: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 'md',
  user: {},
  // showTooltip: true,
  link: null,
  showName: true,
  isSuper: false,
};
export default memo(Avatar);
