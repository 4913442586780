/* eslint-disable no-console */
/* eslint-disable max-len */
/**
 *
 * Modal
 *
 */
import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isFunction } from 'lodash';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Button from '../Button';

export function Modal(props) {
  const {
    show,
    children,
    title,
    onSuccess,
    size,
    successLabel,
    cancelLabel,
    hasCancel,
    simple,
    onCancel,
    TitleIcon,
    hasLeadingIcon,
    hasTrailingIcon,
    TrailingIcon,
    iconType,
    modalType,
    position,
    space,
    disabled,
    showCancelIcon,
    disabledCancel,
  } = props;

  const cancelFn = () => {
    if (onCancel) onCancel();
  };
  const sizeMap = {
    sm: 'max-w-md',
    md: 'max-w-lg',
    lg: 'max-w-xl',
    xl: 'max-w-2xl',
  };

  const positionMap = {
    left: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
  };
  const spaceMap = {
    small: 'space-x-3',
    medium: 'space-x-5',
    large: 'space-x-8',
  };

  const colorMap = {
    primary: 'text-blue-500  bg-sky-50 ',
    danger: 'text-pink-800  bg-red-50',
    bookmark: 'text-amber-800  bg-amber-100',
  };
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={cancelFn}>
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 pointer-events-none" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={` relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4
                 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle
                 sm:p-6  w-full ${get(sizeMap, size)}`}
            >
              <>
                <div className="flex items-center justify-between">
                  <div className="container pt-2 ">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="flex items-center p-1 -mt-4 text-lg font-normal leading-6 text-gray-900"
                      >
                        {TitleIcon && (
                          <div
                            className={`-ml-3 mr-3 mt-1 rounded-full p-3 font-light cursor-pointer ${get(
                              colorMap,
                              iconType,
                            )}`}
                          >
                            <TitleIcon className="w-5 h-5 cursor-pointer" />
                          </div>
                        )}
                        {title}
                      </Dialog.Title>
                    )}
                  </div>
                  <div>
                    {showCancelIcon && (
                      <button
                        type="button"
                        className={classNames('text-black rounded-md focus:outline-none relative cursor-pointer', '')}
                        onClick={cancelFn}
                      >
                        <span className="sr-only">Close</span>

                        <XIcon className="relative w-6 h-6 text-gray-400 hover:text-gray-600" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-2  text-gray-500 ">{children}</div>
                <>
                  {!simple && (
                    <div
                      className={`mt-5 sm:mt-6 flex items-end justify-end space-x-3 ${get(positionMap, position)} ${get(
                        spaceMap,
                        space,
                      )}`}
                    >
                      {hasCancel && (
                        <Button
                          size="sm"
                          type="secondary"
                          onClick={cancelFn}
                          LeadingIcon={hasLeadingIcon ? XIcon : ''}
                          disabled={disabledCancel}
                        >
                          {cancelLabel || <FormattedMessage {...messages.cancel} />}
                        </Button>
                      )}
                      {hasLeadingIcon || hasTrailingIcon ? (
                        <Button
                          type={modalType}
                          size="sm"
                          onClick={() => {
                            if (isFunction(onSuccess)) onSuccess();
                          }}
                          disabled={disabled}
                          LeadingIcon={hasLeadingIcon ? CheckIcon : ''}
                          TrailingIcon={hasTrailingIcon ? TrailingIcon : ''}
                        >
                          {successLabel || <FormattedMessage {...messages.success} />}
                        </Button>
                      ) : (
                        <Button
                          type={modalType}
                          size="sm"
                          onClick={() => {
                            if (isFunction(onSuccess)) onSuccess();
                          }}
                          disabled={disabled}
                          TrailingIcon={hasTrailingIcon ? TrailingIcon : ''}
                        >
                          {successLabel || <FormattedMessage {...messages.success} />}
                        </Button>
                      )}
                    </div>
                  )}
                </>
              </>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onSuccess: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  successLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  cancelLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TitleIcon: PropTypes.object,
  hasCancel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  simple: PropTypes.bool,
  hasLeadingIcon: PropTypes.bool,
  iconType: PropTypes.oneOf(['primary', 'danger', 'bookmark']),
  modalType: PropTypes.oneOf(['primary', 'danger', 'secondary']),
  showCancelIcon: PropTypes.bool,
};

Modal.defaultProps = {
  show: false,
  size: 'md',
  hasCancel: true,
  onSuccess: false,
  simple: false,
  hasLeadingIcon: false,
  modalType: 'primary',
  showCancelIcon: true,
};
export default memo(Modal);
