/*
 * Profile Messages
 *
 * This contains all the text for the Profile component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Profile';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Profile component!',
  },
  changeProfilePhoto: {
    id: `${scope}.changeProfilePhoto`,
    defaultMessage: 'Change Profile Photo',
  },
  addProfilePhoto: {
    id: `${scope}.addProfilePhoto`,
    defaultMessage: 'Add Profile Photo',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  rolesAndOrganization: {
    id: `${scope}.rolesAndOrganization`,
    defaultMessage: 'Role & Organization',
  },
  contactInformation: {
    id: `${scope}.contactInformation`,
    defaultMessage: 'Contact Information',
  },
  lastLogIn: {
    id: `${scope}.lastLogIn`,
    defaultMessage: 'Last Login on ',
  },
  success_message_title: {
    id: `${scope}.success_message_title`,
    defaultMessage: 'Profile pic updated successfully',
  },
  success_message_summary: {
    id: `${scope}.success_message_summary`,
    defaultMessage: 'Your profile pic has been updated successfully!',
  },
  error_message_title: {
    id: `${scope}.error_message_title`,
    defaultMessage: 'Profile pic updating failed',
  },
  error_message_summary: {
    id: `${scope}.error_message_summary`,
    defaultMessage: 'Your profile pic upload has failed!',
  },
});
