/*
 * MoreApps Messages
 *
 * This contains all the text for the MoreApps component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.MoreApps';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the MoreApps component!',
  },
  adminConsole: {
    id: `${scope}.adminConsole`,
    defaultMessage: 'Admin Console',
  },
  adminConsoleSummary: {
    id: `${scope}.adminConsoleSummary`,
    defaultMessage: 'Manage users and configurations.',
  },
  workflowLabel: {
    id: `${scope}.workflowLabel`,
    defaultMessage: 'Workflow tools',
  },
  workflowSummary: {
    id: `${scope}.workflowSummary`,
    defaultMessage: 'Manage workflows, candidates and reporting.',
  },
  recruit: {
    id: `${scope}.recruit`,
    defaultMessage: 'Recruit',
  },
  recruitSummary: {
    id: `${scope}.recruitSummary`,
    defaultMessage: 'Manage candidates and evaluations.',
  },
  campusLabel: {
    id: `${scope}.campusLabel`,
    defaultMessage: 'Campus',
  },
  campusSummary: {
    id: `${scope}.campusSummary`,
    defaultMessage: 'Manage workflows and candidates in high volume',
  },
});
