/**
 *
 * MoreApps
 *
 */

import React, { memo, Fragment, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { faShapes } from '@fortawesome/pro-light-svg-icons';
import { NavigationIcon } from '../NavigationIcon';
import Content from './Content';
import { getItems } from './items';

export function MoreApps(props) {
  const { expanded, userDetails } = props;
  const { username, tenant } = useMemo(() => userDetails, [userDetails]);

  const apps = getItems();

  const handleClick = async path => {
    if (path) {
      // eslint-disable-next-line max-len
      const url = `${process.env.REACT_APP_ADMIN_URL}/login?redirect_url=${path}&tid=${tenant?.id}&username=${username}`;
      window.open(url, '_blank');
    }
  };

  return (
    <div>
      <Popover className="relative">
        <Popover.Button className="outline-none">
          <NavigationIcon icon={faShapes} name="moreAppsIcon" label="More Apps" isChevronRight expanded={expanded} />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className={classNames('absolute bg-white top-0', expanded ? 'left-44' : 'left-16')}>
            <Content handleClick={handleClick} apps={apps} />
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

MoreApps.propTypes = {};

export default memo(MoreApps);
