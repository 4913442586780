import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import classNames from 'classnames';
import { ArrowNarrowUpIcon, ArrowNarrowDownIcon } from '@heroicons/react/solid';

export function Header(props) {
  const { headerGroups, sticky } = props;
  const handleSortBy = column => {
    // set sort desc, asc or none?
    if (!column.disableSortBy) props.sort(column);
  };
  const renderSortIcon = column => {
    if (!column.disableSortBy) {
      if (column.isSortedDesc) return <ArrowNarrowDownIcon className="h-4 w-4" />;
      if (column.isSortedDesc === undefined) return '';
      if (column.isSortedDesc === false) return <ArrowNarrowUpIcon className="h-4 w-4" />;
    }
    return '';
  };

  return (
    <thead className={classNames('bg-slate-50 table-header-group', sticky && 'sticky top-0 z-10')}>
      {map(headerGroups, headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()} key={get(headerGroup, 'headers.0.id')} className="table-row">
          {map(headerGroup.headers, column => (
            <th
              scope="col"
              className={classNames(
                'text-left border-b border-gray-300',
                'text-sm font-normal text-gray-500 bg-gray-50',
                sticky ? 'sticky top-0 z-10 bg-opacity-75 backdrop-blur backdrop-filter ' : '',
                column.sticky && 'sticky bg-grey-50',
                !column.disableSortBy && 'cursor-pointer',
                column.className || '',
              )}
              {...column.getHeaderProps({
                style: { width: column.width, left: column.sticky ? column.totalLeft : '' },
              })}
              key={column.id}
              onClick={() => handleSortBy(column)}
            >
              <div className="inline-flex">
                {column.render('Header')}
                <span>{renderSortIcon(column)}</span>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

Header.propTypes = {
  sticky: PropTypes.bool,
  headerGroups: PropTypes.array,
};

export default memo(Header);
