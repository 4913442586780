/**
 *
 * ApplicationContext
 *
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ApplicationContext = React.createContext({});

function ApplicationProvider(props) {
  const [breadcrumbState, updateBreadcrumb] = useState(props.initialValue || []);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const value = useMemo(
    () => ({ breadcrumbState, updateBreadcrumb, sidebarOpen, setSidebarOpen }),
    [breadcrumbState, updateBreadcrumb, props.initialValue, sidebarOpen, setSidebarOpen],
  );

  return <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>;
}
ApplicationProvider.propTypes = {
  children: PropTypes.any,
};

ApplicationProvider.defaultProps = {};
export const ApplicationConsumer = ApplicationContext.Consumer;

export { ApplicationProvider };

export default ApplicationContext;
