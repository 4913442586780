/**
 *
 * NotificationsPopover
 *
 */

import { faBell } from '@fortawesome/pro-light-svg-icons';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavigationIcon } from '../NavigationIcon';
import Content from './Content';

export function NotificationsPopover(props) {
  const { notifications, expanded } = props;

  return (
    <Popover className="relative">
      <Popover.Button className="outline-none">
        <NavigationIcon icon={faBell} label="Notifications" isChevronRight expanded={expanded} />
      </Popover.Button>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className={classNames('absolute bg-white bottom-0', expanded ? 'left-44 ' : 'left-16')}>
          <Content notifications={notifications} />
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

NotificationsPopover.propTypes = {
  expanded: PropTypes.bool,
};

export default memo(NotificationsPopover);
