import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPenToSquare, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import messages from './messages';
import { PhoneNumberUpdate } from './PhoneNumberUpdate';

function ContactInformation(props) {
  const data = props.userDetails;
  const [editPhoneNumber, toggleEditPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!data?.phone_number) {
      toggleEditPhoneNumber(true);
    }
  }, [data]);

  return (
    <div className="p-4 bg-white border rounded-lg">
      <div className="mb-2 text-sm font-semibold text-gray-900">
        <FormattedMessage {...messages.contactInformation} />
      </div>
      <div className={classNames('flex mb-2', editPhoneNumber ? 'items-start' : 'items-center')}>
        <FontAwesomeIcon icon={faPhone} className="w-4 p-2 text-blue-700 rounded-full bg-blue-50" />
        {editPhoneNumber ? (
          <div className="ml-2">
            <PhoneNumberUpdate
              toggleEditPhoneNumber={toggleEditPhoneNumber}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={data}
            />
          </div>
        ) : (
          <>
            <div className="ml-2 text-sm text-gray-900">{data?.phone_number || '-'}</div>
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="w-4 ml-4 cursor-pointer"
              onClick={() => toggleEditPhoneNumber(true)}
              data-testid="editIcon"
            />
          </>
        )}
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="w-4 p-2 text-blue-700 rounded-full bg-blue-50" />
        <div className="ml-2 text-sm text-gray-900">{data?.email || '-'}</div>
      </div>
    </div>
  );
}

export default ContactInformation;
