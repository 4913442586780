/**
 *
 * NotFound
 *
 */

import React, { memo } from 'react';

import { Loading } from 'components/base';

export function NotFound() {
  return (
    <div className="relative h-screen bg-slate-50">
      <div data-testid="not_found" className="flex items-center justify-center h-full ">
        <Loading />
      </div>
    </div>
  );
}

NotFound.propTypes = {};

export default memo(NotFound);
