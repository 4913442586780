/*
 * Modal Messages
 *
 * This contains all the text for the Modal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Modal';

export default defineMessages({
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Ok',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
});
