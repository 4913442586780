import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';

import { Row } from './Row';

export function Body(props) {
  const { getTableBodyProps, prepareRow, rows, onRowClick } = props;
  return (
    <tbody
      className="divide-y divide-gray-200 bg-white table-row-group leading-none font-light"
      {...getTableBodyProps()}
    >
      {map(rows, (row, index) => {
        prepareRow(row);
        return <Row {...row} index={index} key={get(row, 'id', get(row, 'values.id'))} onRowClick={onRowClick} />;
      })}
    </tbody>
  );
}

Body.propTypes = {
  getTableBodyProps: PropTypes.func,
  prepareRow: PropTypes.func,
  rows: PropTypes.array,
};

Body.defaultProps = {
  rows: [],
};

export default memo(Body);
