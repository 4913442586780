/*
 * Nav Messages
 *
 * This contains all the text for the Nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Nav';

export default defineMessages({
  taxonomy: {
    id: `${scope}.taxonomy`,
    defaultMessage: 'Taxonomy',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  banks: {
    id: `${scope}.banks`,
    defaultMessage: 'Question Banks',
  },
  library: {
    id: `${scope}.library`,
    defaultMessage: 'Assessment Library',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  catalog: {
    id: `${scope}.catalog`,
    defaultMessage: 'Catalog',
  },
  skills: {
    id: `${scope}.skill`,
    defaultMessage: 'Skills',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  apps: {
    id: `${scope}.apps`,
    defaultMessage: 'More Apps',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'Get Help',
  },
});
