import Auth from 'utils/auth';
import { gql } from '@apollo/client';
import { getDefaultContext } from './helpers';

const processQuery = queryString => {
  try {
    return gql`
      ${queryString}
    `;
  } catch (error) {
    return 'Invalid Query';
  }
};

export const makeRequest = async ({ variables, queryString, key, forceRefresh = false, context = {} }) => {
  const apolloClient = await Auth.client();
  const defaultContext = getDefaultContext(Auth.graphToken);
  const { data } = await apolloClient.query({
    query: queryString,
    variables,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    context: {
      ...defaultContext,
      ...context,
    },
  });
  return key ? data[key] : data;
};

const fetchData = async ({ queryString, queryKey, queryVariables = {}, forceRefresh, context }) => {
  if (!queryString) {
    return [];
  }
  const graphQuery = typeof queryString === 'string' ? processQuery(queryString) : queryString;
  if (graphQuery === 'Invalid Query') {
    return [];
  }
  const response = await makeRequest({
    variables: {
      ...queryVariables,
    },
    key: queryKey,
    queryString: graphQuery,
    forceRefresh,
    context,
  });

  return response;
};

export default fetchData;
