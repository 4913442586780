import Auth from 'utils/auth';
import { processQuery } from 'utils/processQuery';
import { getDefaultContext } from './helpers';

export const makeRequest = async ({ payload, queryString, context = {}, spreadPayload }) => {
  const apolloClient = await Auth.client();
  const defaultContext = getDefaultContext(Auth.graphToken);
  return apolloClient.mutate({
    mutation: queryString,
    variables: spreadPayload ? { ...payload } : { payload },
    context: {
      ...defaultContext,
      ...context,
    },
  });
};

const postData = async ({ queryString, payload, spreadPayload = false, context = {} }) => {
  if (!queryString) {
    throw Error('queryString not provided');
  }
  const graphQuery = processQuery(queryString);
  if (graphQuery?.errorMessage === 'Invalid Query') {
    throw graphQuery;
  }
  const response = await makeRequest({
    payload,
    queryString: graphQuery,
    spreadPayload,
    context,
  });

  return response;
};

export default postData;
