import { gql } from '@apollo/client';

export const processQuery = queryString => {
  try {
    return gql`
      ${queryString}
    `;
  } catch (error) {
    return { errorMessage: 'Invalid Query', error };
  }
};
