import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faHandHoldingHeart, faScroll, faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handleEnterKeyPress } from 'utils/helpers';
import messages from './messages';

function Content(props) {
  const { showReportIssue, showRequestFeature } = props;

  const handleDocumentationLink = () => {
    const redirectURL = 'https://talview.freshdesk.com/en/support/solutions';
    window.open(redirectURL, '_blank');
  };

  return (
    <>
      <div className="w-52 border rounded p-2 text-sm text-gray-700 py-2.5 px-2.5">
        <ol className="space-y-3">
          <li className="cursor-pointer hover:bg-gray-100 px-2 py-1">
            <div
              role="button"
              tabIndex="-1"
              onClick={showReportIssue}
              onKeyDown={e => handleEnterKeyPress(e, showReportIssue)}
              className="flex"
              data-testid="report-an-issue"
            >
              <div className="w-6 mr-1">
                <FontAwesomeIcon icon={faHeadset} className="text-lg" />
              </div>
              <FormattedMessage {...messages.report_an_issue} />
            </div>
          </li>
          <li className="cursor-pointer hover:bg-gray-100 px-2 py-1">
            <div
              role="button"
              tabIndex="-1"
              onClick={showRequestFeature}
              onKeyDown={e => handleEnterKeyPress(e, showRequestFeature)}
              className="flex"
              data-testid="request-feature"
            >
              <div className="w-6 mr-1">
                <FontAwesomeIcon icon={faHandHoldingHeart} className="text-lg" />
              </div>
              <FormattedMessage {...messages.request_a_feature} />
            </div>
          </li>
          <li className="cursor-pointer hover:bg-gray-100 px-2 py-1">
            <div
              role="button"
              tabIndex="-1"
              onClick={handleDocumentationLink}
              onKeyDown={e => handleEnterKeyPress(e, handleDocumentationLink)}
              className="flex justify-between"
              data-testid="documentation"
            >
              <div className="flex">
                <div className="w-6 mr-1">
                  <FontAwesomeIcon icon={faScroll} className="text-lg" />
                </div>
                <FormattedMessage {...messages.documentation} />
              </div>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-sm" />
            </div>
          </li>
        </ol>
      </div>
    </>
  );
}

export default Content;
