import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export function Cell(props) {
  const { getCellProps, column, render } = props;
  return (
    <td
      {...getCellProps({
        className: classNames(column.cellClassName, 'truncate text-sm', column.sticky && 'sticky bg-white'),
        style: { left: column.sticky ? column.totalLeft : '' },
      })}
    >
      {render('Cell')}
    </td>
  );
}
Cell.propTypes = {
  getCellProps: PropTypes.func,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  column: PropTypes.object,
  render: PropTypes.func,
};

export default memo(Cell);
