import { gql } from '@apollo/client';

export const GET_DROPDOWN_ITEMS = gql`
  query MyQuery {
    qbm_question_level {
      name
    }
    qbm_skill {
      id
      name
    }
    qbm_blooms_taxonomy {
      name
    }
    qbm_language {
      value
    }
    qbm_question_status {
      name
    }
    qbm_question(distinct_on: id) {
      userByCreatedBy {
        id
        name
        email
      }
    }
  }
`;

export const GET_QUESTION_LANGUAGE = gql`
  query MyQuery($limit: Int, $offset: Int, $value: String_comparison_exp = {}) {
    qbm_language(limit: $limit, offset: $offset, where: { value: $value }) {
      value
    }
  }
`;

// ____________ required data for insert question
// {
//   "data": [
//     {
//       "id": 10,
//       "question_skills": { "skill_id": 10 },
//       "language_allowed": "ANY_or_ALL",
//       "question_blooms_taxonomies": { "blooms_taxonomy_name": "Analysis" },
//       "question_choices": { "choice_order": 10, "value": "", "is_correct": false },
//       "is_shuffle": false,
//       "level": "L2",
//       "preparation_time": "",
//       "question_languages": { "value": "" },
//       "reviewer_id": 10,
//       "status": "ACCEPTED",
//       "title": "",
//       "answer_time": "",
//       "content": "",
//       "external_id": ""
//     }
//   ]
// }
export const UPDATE_QUESTION = gql`
  mutation MyMutation($data: [qbm_update_question_input]! = [qbm_update_question_input]) {
    qbm_update_question(data: $data) {
      data {
        content
        external_id
        id
        level
        question_bank_id
        question_blooms_taxonomies {
          blooms_taxonomy_name
          id
        }
        question_choices {
          choice_order
          id
          is_correct
          value
        }
        question_languages {
          id
          value
        }
        question_skills {
          id
          skill_id
        }
        request_skill_id
        review_requested_at
        reviewer_id
        status
        title
      }
      error_message
      success
    }
  }
`;

export const INSERT_BULK = gql`
  mutation InsertBulkQuestion($insertarray: [qbm_question_insert_input!]!) {
    insert_qbm_question(objects: $insertarray) {
      returning {
        id
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation MyMutation($filename: String!, $type: String!) {
    file_upload_file(extension: $type, file_type_id: 4, original_name: $filename, owner_id: 4) {
      id
      data
    }
  }
`;

export const QUERY_UPLOAD_FILE = `
mutation uploadFile($file_type_id: Int!, $original_name: String!, $owner_id: Int!, $extension:String!){
  file_upload_file( file_type_id : $file_type_id , original_name:$original_name ,
  owner_id:  $owner_id,extension: $extension ){
    id
    data
    expires_in
    key
  }
}
`;

export const DOWNLOAD_FILE = gql`
  query MyQuery($id: Int!) {
    file_get_file(id: $id) {
      original_filename
      resource_url
    }
  }
`;
